import { SET_SCHOOL_INFO, SET_SCHOOL_INFO_ERROR, SET_SCHOOL_SESSION, SET_SECTIONS_LIST, SET_SESSION_LIST } from "../../ActionTypes";

const InitialState = {
    localSessionId: '',
    sectionsList: [],
    sessionList: [],
    schoolInfo: null,
    schoolInfoError: null,
}

const SchoolReducer = (state = InitialState, action) => {
    switch (action.type) {
        case SET_SCHOOL_SESSION: return {
            ...state,
            localSessionId: action.payload
        };
        case SET_SESSION_LIST: return {
            ...state,
            sessionList: action.payload
        }
        case SET_SECTIONS_LIST: return {
            ...state,
            sectionsList: action.payload
        }
        case SET_SCHOOL_INFO: return {
            ...state,
            schoolInfo: action.payload,
            schoolInfoError: null
        }
        case SET_SCHOOL_INFO_ERROR: return {
            ...state,
            schoolInfoError: action.payload,
            schoolInfo: null
        }
        default: return state;
    }
}

export default SchoolReducer;