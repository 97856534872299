import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import navigationConfig from "../../../../configs/navigationConfig";
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { history } from "../../../../history";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserInfo } from "../../../../store/actions/LoginAction";
import { setPermisssionOrder } from "../../../../utils/SideMenuTabsList";
import { ROLES_BY_NAME } from "../../../../utils/constants/Roles";
import { getKeyFromToken, TOKEN_PERMISSION_KEY } from "../../../../utils/localStorageConstants";

const SideMenuContent = ({ getUserInfo, users, loading, activePath, handleActiveItem, ...props }) => {
  const [activeGroups, setActiveGroups] = useState([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
  const [tempArr, setTempArr] = useState([]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const handleGroupClick = useCallback((id, parent = null, type = "") => {
    let open_group = [...activeGroups];
    let active_group = [...currentActiveGroup];
    let temp_arr = [...tempArr];

    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr?.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [parent];
      }
      active_group = [...temp_arr];
    } else if (type === "collapse" && parent === null) {
      temp_arr = [id];
    } else if (type === "collapse" && parent !== null) {
      if (active_group?.includes(parent)) {
        temp_arr = [...active_group];
      }
      if (temp_arr?.includes(id)) {
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      if (!open_group?.includes(id)) {
        let temp = open_group?.filter(obj => !active_group?.includes(obj));
        if (temp.length > 0 && !open_group?.includes(parent)) {
          open_group = open_group?.filter(obj => !temp?.includes(obj));
        }
        if (open_group?.includes(parent) && active_group?.includes(parent)) {
          open_group = [...active_group];
        }
        if (!open_group?.includes(id)) {
          open_group.push(id);
        }
      } else {
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = [...active_group];
    }

    setActiveGroups(open_group);
    setTempArr(temp_arr);
    setCurrentActiveGroup(active_group);
  }, [activeGroups, currentActiveGroup, tempArr]);

  const redirectUnauthorized = useCallback(() => {
    history.push("/");
  }, []);

  const menuItems = useMemo(() => {
    const permissionObject = getKeyFromToken(TOKEN_PERMISSION_KEY);
    const permissions = permissionObject && Object.keys(permissionObject);
    let orderedPermission = setPermisssionOrder(permissions, users.role);

    let role = users.role;
    if (orderedPermission?.includes("dashboardEmployee") || users.role === "Principal") {
      role = ROLES_BY_NAME.Employee;
    } else if (orderedPermission?.includes("dashboardSuperAdmin")) {
      role = ROLES_BY_NAME.SuperAdmin;
    } else if (orderedPermission?.includes("dashboardSubAdmin")) {
      role = ROLES_BY_NAME.SubAdmin;
    }

    let navigation = navigationConfig(orderedPermission, role);
    navigation = navigation?.filter(item => item);

    return navigation?.map(item => {
      const CustomAnchorTag = item && item.type === "external-link" ? "a" : Link;

      if (item.type === "groupHeader") {
        return (
          <li className="navigation-header" key={`group-header-${item.groupTitle}`}>
            <span>{item.groupTitle}</span>
          </li>
        );
      }

      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "collapse",
            open: activeGroups?.includes(item.id),
            "sidebar-group-active": currentActiveGroup?.includes(item.id),
            hover: props.hoverIndex === item.id,
            active: (item.navLink === window.location.pathname),
            disabled: item.disabled
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            if (item.type === "item") {
              handleActiveItem(item.navLink);
              handleGroupClick(item.id, null, item.type);
              if (props.deviceWidth <= 1200 && item.type === "item") {
                props.toggleMenu();
              }
            } else {
              handleGroupClick(item.id, null, item.type);
            }
          }}
        >
          <CustomAnchorTag
            to={item?.filterBase ? item?.filterBase : item.navLink && item.type === "item" ? item.navLink : ""}
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${item.badgeText ? "justify-content-between" : "justify-content-start"}`}
            onMouseEnter={() => props.handleSidebarMouseEnter(item.id)}
            onMouseLeave={() => props.handleSidebarMouseEnter(item.id)}
            key={item.id}
            onClick={(e) => item.type === "collapse" ? e.preventDefault() : ""}
            target={item.newTab ? "_blank" : undefined}
            style={{ marginRight: "20px" }}
          >
            <div>
              {item.icon}
              <span className="menu-item menu-title" style={{ fontSize: "1em" }}>
                <strong>{item.title}</strong>
              </span>
            </div>

            {item.badge && (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            )}
            {item.type === "collapse" && (
              <ChevronRight className="menu-toggle-icon" size={13} />
            )}
          </CustomAnchorTag>
          {item.type === "collapse" && (
            <SideMenuGroup
              group={item}
              handleGroupClick={handleGroupClick}
              activeGroup={activeGroups}
              handleActiveItem={handleActiveItem}
              activeItemState={props.activeItemState}
              handleSidebarMouseEnter={props.handleSidebarMouseEnter}
              activePath={activePath}
              hoverIndex={props.hoverIndex}
              initRender={props.initRender}
              parentArr={props.parentArr}
              triggerActive={undefined}
              currentActiveGroup={currentActiveGroup}
              permission={props.permission}
              currentUser={props.currentUser}
              redirectUnauthorized={redirectUnauthorized}
              collapsedMenuPaths={props.collapsedMenuPaths}
              toggleMenu={props.toggleMenu}
              deviceWidth={props.deviceWidth}
            />
          )}
          <hr />
        </li>
      );

      if (
        item.type === "collapse" ||
        item.type === "external-link" ||
        (item.type === "item" &&
          item.permissions &&
          item.permissions?.includes(props.currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem;
      } else if (
        item.type === "item" &&
        item.navLink === activePath &&
        !item.permissions?.includes(props.currentUser)
      ) {
        return redirectUnauthorized();
      }
    });
  }, [users.role, handleGroupClick, redirectUnauthorized, props, activeGroups, currentActiveGroup, handleActiveItem, activePath]);

  if (loading || loading === undefined) {
    return <div />;
  }

  return <>{menuItems}</>;
};
SideMenuContent.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  users: state.login.users,
  loading: state.login.loading,
});

const mapDispatchToProps = dispatch => ({
  getUserInfo: () => dispatch(getUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuContent);