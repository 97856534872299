import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import { loginReducer } from './LoginReducer'
import countryStateReducer from './countryStateReducer/CountryStateReducer';
import SchoolReducer from './school/SchoolReducer';
import LoaderReducer from './loaderReducer/LoaderReducer';
import DashboardReducer from "./Dashboard/DashboardReducer";
import ExamReducer from "./examReducer/ExamReducer";

export default combineReducers({
  customizer: customizer,
  auth: auth,
  login: loginReducer,
  countryStateReducer,
  SchoolReducer,
  LoaderReducer,
  DashboardReducer,
  ExamReducer
});

