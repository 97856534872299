import React from "react";
import * as Icon from "react-feather";
import { SUB_ADMIN_ROUTES, SUPER_ADMIN_ROUTES, ADMIN_ROUTES } from "../utils/constants/RouterConstants";

const navigationConfig = (permissions, role) => {
  return permissions?.map((item) => {
    switch (item) {
      /*Super Admin Section*/
      case "dashboardSuperAdmin": {
        return {
          id: "dashboardSuperAdmin",
          title: "Dashboard",
          type: "item",
          icon: <Icon.BarChart2 size={20} />,
          permissions: ["admin", "editor"],
          navLink: SUPER_ADMIN_ROUTES.DASHBOARD,
        };
      }

      case "manageSuperAdminUsers": {
        return {
          id: "manageSuperAdminUsers",
          title: "Manage Users",
          type: "item",
          icon: <Icon.User size={20} />,
          permissions: ["admin", "editor"],
          // navLink: "/manage/super-admin"
          navLink: SUPER_ADMIN_ROUTES.MANAGEUSER,
        };
      }

      case "schoolRequests": {
        return {
          id: "schoolRequests",
          title: "School Requests",
          type: "item",
          icon: <Icon.FilePlus size={20} />,
          permissions: ["admin", "editor"],
          badge: "warning",
          navLink: SUPER_ADMIN_ROUTES.SCHOOL_REQUEST_LISTING,
        };
      }

      case "createSchools": {
        return {
          id: "createSchools",
          title: "Schools Section",
          type: "item",
          icon: <Icon.Home size={20} />,
          permissions: ["admin", "editor"],
          // navLink: "/school"
          navLink: SUPER_ADMIN_ROUTES.SCHOOL_LISTING,
        };
      }
      case "superAdminCommunication": {
        return {
          id: "superAdminCommunication",
          title: "Communication",
          type: "item",
          icon: <Icon.MessageCircle size={20} />,
          permissions: ["admin", "editor"],
          // navLink: "/communication"
          navLink: SUPER_ADMIN_ROUTES.COMMUNICATION,
        };
      }

      case "superAdminReports": {
        return {
          id: "superAdminReports",
          title: "Report",
          type: "item",
          icon: <Icon.FileText size={20} />,
          permissions: ["admin", "editor"],
          navLink: SUPER_ADMIN_ROUTES.REPORT,
        };
      }

      /*Admin Section*/
      case "dashboardAdmin": {
        return {
          id: "dashboardAdmin",
          title: "Dashboard",
          type: "item",
          icon: <Icon.Home size={20} />,
          permissions: ["admin", "editor"],
          navLink: ADMIN_ROUTES.DASHBOARD,
        };
      }
      case "manageSchools": {
        return {
          id: "manageSchools",
          title: "Manage School",
          type: "item",
          icon: <Icon.User size={20} />,
          permissions: ["admin", "editor"],
          navLink: ADMIN_ROUTES.ADMIN_MANAGE_SCHOOL,
        };
      }

      /*Employee Section*/
      case "dashboardEmployee": {
        return {
          id: "dashboardEmployee",
          title: "Dashboard",
          type: "item",
          icon: <Icon.BarChart2 size={20} />,
          permissions: ["admin", "editor"],
          // navLink: "/employee"
          navLink: `/${role}${SUB_ADMIN_ROUTES.DASHBOARD}`,
        };
      }

      /*SubAdmin Login*/
      case "dashboardSubAdmin": {
        return {
          id: "dashboardSubAdmin",
          title: "Dashboard",
          type: "item",
          icon: <Icon.BarChart2 size={20} />,
          // navLink: "/subAdmin"
          navLink: `/${role}${SUB_ADMIN_ROUTES.DASHBOARD}`,
        };
      }
      case "manageSubAdminUsers": {
        return {
          id: "manageSubAdminUsers",
          title: "Manage Users",
          type: "item",
          icon: <Icon.Users size={20} />,
          // navLink: "/user/listing"
          navLink: `/${role}${SUB_ADMIN_ROUTES.MANAGEUSER}`,
        };
      }

      case "studentRegistration": {
        return {
          id: "studentRegistration",
          title: "Admission",
          type: "item",
          icon: <Icon.Edit size={20} />,
          navLink: `/${role}${SUB_ADMIN_ROUTES.ADMISSION_ACCESS}`,
        };
      }

      case "schoolSettings": {
        return {
          id: "schoolSettings",
          title: "School",
          type: "item",
          icon: <Icon.Home size={20} />,
          // navLink: "/school/info"
          navLink: `/${role}${SUB_ADMIN_ROUTES.SCHOOL_INFO}`,
        };
      }
      case "classSettings": {
        return {
          id: "classSettings",
          title: "Class",
          type: "item",
          permissions: ["admin", "editor"],
          icon: <Icon.BookOpen size={20} />,
          navLink: `/${role}${SUB_ADMIN_ROUTES.CLASS_VIEW_CLASSES}`,
        };
      }


      case "studentInfo": {
        return {
          id: "viewStudents",
          title: "Students",
          type: "item",
          icon: <Icon.UserPlus size={20} />,
          // navLink: "/students/view"
          navLink: `/${role}${SUB_ADMIN_ROUTES.STUDENT_LISTING}`,
          // children:[
          //   {
          //     id: "addStudents",
          //     title: "Add Students",
          //     type: "item",
          //     permissions: ["admin", "editor"],
          //     navLink: "/students/add"
          //   },
          //   {
          //     id: "viewStudents",
          //     title: "View Students",
          //     type: "item",
          //     permissions: ["admin", "editor"],
          //     navLink: "/students/view"
          //   },
          // ]
        };
      }
      case "teacherInfo": {
        return {
          id: "teacherInfo",
          title: "Teachers",
          type: "item",
          icon: <Icon.UserPlus size={20} />,
          // navLink: "/teacher/view"
          navLink: `/${role}${SUB_ADMIN_ROUTES.TEACHER_LISTING}`,
          // children:[
          //   {
          //     id: "addTeachers",
          //     title: "Add Teachers",
          //     type: "item",
          //     permissions: ["admin", "editor"],
          //     navLink: "/teacher/add"
          //   },
          //   {
          //     id: "viewTeachers",
          //     title: "View Teachers",
          //     type: "item",
          //     permissions: ["admin", "editor"],
          //     navLink: "/teacher/view"
          //   },
          // ]
        };
      }
      // case "homework": {
      //   return {
      //     id: "homework",
      //     title: "Homework",
      //     type: "item",
      //     icon: <Icon.FileText size={20} />,
      //     // navLink: "/homework"
      //     navLink: `/${role}${SUB_ADMIN_ROUTES.HOMEWORK}`,
      //   };
      // }



      case "subAdminCommunication": {
        return {
          id: "subAdminCommunication",
          title: "Communication",
          type: "item",
          navLink: `/${role}${SUB_ADMIN_ROUTES.COMMUNICATION}`,
          icon: <Icon.Mail size={20} />,
          // children: [
          //   {
          //     id: "adminCommunication",
          //     title: "Admin Communication",
          //     type: "item",
          //     icon: <Icon.Inbox size={20} />,
          //     // navLink: "/sub-communication"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.COMMUNICATION}`,
          //   },
          //   {
          //     id: "messages",
          //     title: "Messages",
          //     type: "item",
          //     icon: <Icon.Inbox size={20} />,
          //     // navLink: "/communication/message"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.COMMUNICATION_MESSAGE}`,
          //   },
          //   {
          //     id: "noticeBoard",
          //     title: "Notice Board",
          //     type: "item",
          //     icon: <Icon.Inbox size={20} />,
          //     // navLink: "/communication/notice-board"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.COMMUNICATION_NOTICE_BOARD}`,
          //   },
          //   {
          //     id: "communicationTransport",
          //     title: "Transport",
          //     type: "item",
          //     icon: <Icon.Inbox size={20} />,
          //     // navLink: "/communication/transport"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.COMMUNICATION_TRANSPORT}`,
          //   },
          //   {
          //     id: "notification",
          //     title: "Notifications",
          //     type: "item",
          //     icon: <Icon.Inbox size={20} />,
          //     // navLink: "/communication/notification"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.COMMUNICATION_NOTIFICATION}`,
          //   },
          // ],
        };
      }
      case "teacherAttendance": {
        return {
          id: "teacherAttendance",
          title: "Emp. Attendance",
          type: "item",
          icon: <Icon.UserCheck size={20} />,
          // navLink: "/teacher-attendance/take"
          navLink: `/${role}${SUB_ADMIN_ROUTES.TEACHER_ATTENDANCE_TAKE}`,
        };
      }
      case "studentAttendance": {
        return {
          id: "studentAttendance",
          title: "Stu. Attendance",
          type: "item",
          icon: <Icon.UserCheck size={20} />,
          // navLink: "/attendance/student/daily-report"
          navLink: `/${role}${SUB_ADMIN_ROUTES.STUDENT_ATTENDANCE_DAILY_REPORT}`,
        };
      }
      case "timeTable": {
        return {
          id: "timeTable",
          title: "Time Table",
          type: "item",
          permissions: ["admin", "editor"],
          navLink: `/${role}${SUB_ADMIN_ROUTES.TIME_TABLE_VIEW}`,
          icon: <Icon.Bell size={20} />,
        };
      }


      case "calendar": {
        return {
          id: "calendar",
          title: "Calendar",
          type: "item",
          icon: <Icon.Calendar size={20} />,
          // navLink: "/calendar/holiday"
          navLink: `/${role}${SUB_ADMIN_ROUTES.CALENDAR_HOLIDAY}`,
        };
      }
      case "certificates": {
        return {
          id: "certificate",
          title: "Certificate",
          type: "item",
          icon: <Icon.Clipboard size={20} />,
          // navLink: "/certificate"
          navLink: `/${role}${SUB_ADMIN_ROUTES.CERTIFICATES}`,
        };
      }
      case "gallery": {
        return {
          id: "gallery",
          title: "Gallery",
          type: "item",
          icon: <Icon.Camera size={20} />,
          // navLink: "/gallery"
          navLink: `/${role}${SUB_ADMIN_ROUTES.GALLERY}`,
        };
      }
      case "syllabus": {
        return {
          id: "syllabus",
          title: "Student Syllabus",
          type: "item",
          icon: <Icon.BookOpen size={20} />,
          // navLink: "/student-syllabus"
          navLink: `/${role}${SUB_ADMIN_ROUTES.STUDENT_SYLLABUS}`,
        };
      }
      case "diary": {
        return {
          id: "diary",
          title: "Student Diary",
          type: "item",
          icon: <Icon.Book size={20} />,
          // navLink: "/student-diary"
          navLink: `/${role}${SUB_ADMIN_ROUTES.STUDENT_DIARY}`,
        };
      }

      case "income": {
        return {
          id: "income",
          title: "Income",
          type: "item",
          navLink: `/${role}${SUB_ADMIN_ROUTES.INCOME}`,
          icon: <Icon.Truck size={20} />,
        };
      }

      case "expenditure": {
        return {
          id: "expenditure",
          title: "Expenditure",
          type: "item",
          navLink: `/${role}${SUB_ADMIN_ROUTES.EXPENDITURE}`,
          icon: <Icon.Eye size={20} />,
        };
      }
      case "transport": {
        return {
          id: "transport",
          title: "Transport",
          type: "item",
          icon: <Icon.BookOpen size={20} />,
          navLink: `/${role}${SUB_ADMIN_ROUTES.TRANSPORT}`,
        };
      }

      // case "leaveManagement": {
      //   return {
      //     id: "leaveManagement",
      //     title: "LeaveManagement",
      //     type: "item",
      //     icon: <Icon.Layers size={20} />,
      //     navLink: `/${role}${SUB_ADMIN_ROUTES.LEAVE_MANAGEMENT}`,
      //   };
      // }

      case "fee": {
        return {
          id: "fee",
          title: "Fee",
          type: "item",
          permissions: ["admin", "editor"],
          navLink: `/${role}${SUB_ADMIN_ROUTES.FEE_PAYMENT}`,
          icon: <Icon.Briefcase size={20} />,
          // children: [
          //   {
          //     id: "payment",
          //     title: "Payment",
          //     type: "item",
          //     permissions: ["admin", "editor"],
          //     // navLink: "/fee/payment"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.FEE_PAYMENT}`,
          //   },
          //   {
          //     id: "report",
          //     title: "Report",
          //     type: "item",
          //     permissions: ["admin", "editor"],
          //     // navLink: "/fee/payment"
          //     navLink: `/${role}${SUB_ADMIN_ROUTES.FEE_REPORT}`,
          //   },
          // ],
        };
      }
      // case "exam": {
      //   return {
      //     id: "exam",
      //     title: "Exam",
      //     permissions: ["admin", "editor"],
      //     type: "item",
      //     icon: <Icon.Clipboard size={20} />,
      //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM}`,
      //     // children: [
      //     //   {
      //     //     id: "settings",
      //     //     title: "Settings",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_SETTING}`,
      //     //   },
      //     //   {
      //     //     id: "exam pattern",
      //     //     title: "Exam Pattern",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_PATTERN}`,
      //     //   },
      //     //   {
      //     //     id: "exam schedule",
      //     //     title: "Exam Schedule",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_SCHEDULE}`,
      //     //   },
      //     //   {
      //     //     id: "marks allocation ",
      //     //     title: "Marks Allocation",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_ALLOCATION}`,
      //     //   },
      //     //   {
      //     //     id: "exam publish ",
      //     //     title: "Exam Publish",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_PUBLISH}`,
      //     //   },
      //     //   {
      //     //     id: "exam report ",
      //     //     title: "Exam Report",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_REPORT}`,
      //     //   },
      //     //   {
      //     //     id: "exam card ",
      //     //     title: "Exam Card",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM}`,
      //     //   },
      //     //   {
      //     //     id: "exam supplementary ",
      //     //     title: "Exam Supplementary",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.EXAM_SUPPLEMENTARY}`,
      //     //   },
      //     //   {
      //     //     id: "exam remark ",
      //     //     title: "Exam Remark",
      //     //     type: "item",
      //     //     permissions: ["admin", "editor"],
      //     //     // navLink: "/fee/configure-fee"
      //     //     navLink: `/${role}${SUB_ADMIN_ROUTES.SUBJECT_RAMARK}`,
      //     //   },
      //     // ],
      //   };
      // }
    }
  });
};

export default navigationConfig;
