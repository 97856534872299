import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { history } from "../../history";
import PerfectScrollbar from "react-perfect-scrollbar";

const keyMapper = {
  students: 'Student',
  teachers: 'Teacher'
};

const GlobalSearchAutoComplete = (props) => {
  const [state, setState] = useState({
    activeSuggestion: 0,
    showSuggestions: false,
    userInput: "",
    focused: false,
    openUp: false
  });

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const suggestionListRef = useRef(null);
  const filteredDataRef = useRef([]);
  const debounceTimerRef = useRef(null);

  const student = [ 'Admission No.', 'Name', 'Class', 'FatherName', 'MotherName', 'Contact','Type', 'Action'];
  const teacher = ['EmpId','Name', 'FatherName','Contact', 'Role', 'Designation','Action'];

  useEffect(() => {
    document.body.addEventListener("click", handleExternalClick);
    return () => {
      document.body.removeEventListener("click", handleExternalClick);
    };
  }, []);

  useEffect(() => {
    if (props.defaultSuggestions && state.focused) {
      setState(prev => ({ ...prev, showSuggestions: true }));
    }
  }, [props.defaultSuggestions, state.focused]);

  useEffect(() => {
    if (inputRef.current && props.autoFocus) {
      inputRef.current.focus();
    }

    if (props.defaultSuggestions && !state.showSuggestions && state.focused) {
      setState(prev => ({ ...prev, showSuggestions: true }));
    }

    if (props.clearInput === false && state.userInput.length) {
      setState(prev => ({ ...prev, userInput: "" }));
    }

    if (props.onSuggestionsShown && state.showSuggestions) {
      props.onSuggestionsShown(state.userInput);
    }
  }, [props, state]);

  const handleExternalClick = useCallback((e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setState(prev => ({ ...prev, showSuggestions: false }));
      if (props.externalClick) props.externalClick(e);
    }
  }, [props]);

  const onSuggestionItemClick = useCallback((url, e) => {
    if (props.onSuggestionItemClick) {
      props.onSuggestionItemClick(e);
    }
    setState(prev => ({
      ...prev,
      activeSuggestion: 0,
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    }));
    if (url) history.push(url);
  }, [props]);

  const debouncedApiCall = useCallback((input) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      // Call your API here
      console.log("Calling API with input:", input);
      // Update suggestions based on API response
      // props.updateSuggestions(apiResponse);
    }, 1000);
  }, []);

  const onChange = useCallback((e) => {
    const userInput = e.currentTarget.value;
    setState(prev => ({
      ...prev,
      activeSuggestion: 0,
      showSuggestions: userInput.length > 0,
      userInput
    }));
    if (props.onChange) {
      props.onChange(e);
    }
    if (userInput.length >= 3) {
      debouncedApiCall(userInput);
    }
  }, [props, debouncedApiCall]);

  const onKeyDown = useCallback((e) => {
    const { activeSuggestion, showSuggestions, userInput } = state;
    const filterKey = props.filterKey;
    let suggestionList = suggestionListRef.current;

    if (e.keyCode === 38 && activeSuggestion !== 0) {
      setState(prev => ({ ...prev, activeSuggestion: prev.activeSuggestion - 1 }));
      if (
        e.target.value.length > -1 &&
        suggestionList &&
        activeSuggestion <= filteredDataRef.current.length / 2
      ) {
        suggestionList.scrollTop = 0;
      }
    } else if (
      e.keyCode === 40 &&
      activeSuggestion < filteredDataRef.current.length - 1
    ) {
      setState(prev => ({ ...prev, activeSuggestion: prev.activeSuggestion + 1 }));
      if (
        e.target.value.length > -1 &&
        suggestionList &&
        activeSuggestion >= filteredDataRef.current.length / 2
      ) {
        suggestionList.scrollTop = suggestionList.scrollHeight;
      }
    } else if (e.keyCode === 27) {
      setState(prev => ({
        ...prev,
        showSuggestions: false,
        userInput: ""
      }));
    } else if (e.keyCode === 13 && showSuggestions) {
      onSuggestionItemClick(filteredDataRef.current[activeSuggestion].link, e);
      setState(prev => ({
        ...prev,
        userInput: filteredDataRef.current[activeSuggestion][filterKey],
        showSuggestions: false
      }));
    } else {
      if (props.onKeyDown) {
        props.onKeyDown(e, userInput);
      }
      return;
    }
  }, [state, props, onSuggestionItemClick]);
  
  const renderSuggestions = useCallback(() => {
    const { suggestions, customRender } = props;
    filteredDataRef.current = [];
    return Object.keys(suggestions).map((key) => {
      if (suggestions[key].length === 0) return null;
      
      filteredDataRef.current = [...filteredDataRef.current, ...suggestions[key]];
      
      return (
        <React.Fragment key={key}>
          <li style={{marginLeft: '15px'}} className="suggestion-item suggestion-title text-primary text-bold-600">
            {key.toUpperCase()}
          </li>
          <table style={{ marginLeft: '25px' }} className="table table-striped">
            <thead>
              <tr>
                {(keyMapper[key].toLowerCase() === 'student' ? student : teacher).map(elem => (
                  <th key={elem}>{elem}</th>
                ))}
              </tr>
            </thead>
            {suggestions[key].map((value, index) => 
              customRender({
                value,
                index,
                type: keyMapper[key]
              })
            )}
          </table>
        </React.Fragment>
      );
    });
  }, [props, student, teacher]);

  const { showSuggestions, userInput, openUp } = state;
  let suggestionsListComponent;

  if (showSuggestions) {
    suggestionsListComponent = (
      <PerfectScrollbar
        className={classnames("suggestions-list", {
          "open-up": openUp
        })}
        containerRef={(ref) => {
          suggestionListRef.current = ref;
        }}
        component="ul"
        options={{ wheelPropagation: false }}>
        {renderSuggestions()}
      </PerfectScrollbar>
    );
  }

  return (
    <div className="vx-autocomplete-container" ref={containerRef}>
      <input
        style={{backgroundColor:"black",color:"white"}}
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        className={`vx-autocomplete-search ${props.className || ""}`}
        placeholder={props.placeholder}
        onClick={(e) => e.stopPropagation()}
        ref={inputRef}
        onFocus={() => setState(prev => ({ ...prev, focused: true }))}
        autoFocus={props.autoFocus}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
          setState(prev => ({ ...prev, focused: false }));
        }}
      />
      {suggestionsListComponent}
    </div>
  );
};

GlobalSearchAutoComplete.propTypes = {
  suggestions: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterHeaderKey: PropTypes.string,
  placeholder: PropTypes.string,
  suggestionLimit: PropTypes.number,
  grouped: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onSuggestionsShown: PropTypes.func,
  onSuggestionItemClick: PropTypes.func,
  externalClick: PropTypes.func,
  clearInput: PropTypes.bool,
  defaultSuggestions: PropTypes.bool,
  customRender: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default GlobalSearchAutoComplete;