import { SET_STATES_LIST } from "../../ActionTypes";

export const InitialState = {
    statesList:[]
}

const countryStateReducer = (state=InitialState, action) => {
    switch(action.type) {
        case SET_STATES_LIST : return { ...state, statesList: action.payload};
        default : return state;
    }
}

export default countryStateReducer;