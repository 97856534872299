import decode from 'jwt-decode';

// keys  for local storage 
export const LOCAL_SESSION_ID = 'localSessionId';
export const TOKEN = 'token';
export const TOKEN_PERMISSION_KEY = 'permissions';
export const TOKEN_SCHOOL_ID = 'schoolId';
export const TOKEN_ROLE_KEY = 'role';


export const getLocalSessionId = () => localStorage.getItem(LOCAL_SESSION_ID);

export const getTokenFromLocalStorage = () => localStorage.getItem(TOKEN);

// function to decode a token ad the return value as per the key from decoded token
export const getKeyFromToken = (key, token) => {
    if (token && decode(token)) {
        const decodeResponse = decode(token);
        return decodeResponse[key];
    }
    else {
        const localToken = localStorage.getItem(TOKEN);
        if(localToken && decode(localToken)) {
            return decode(localToken)[key];
        }
    }
    return null;
}