import { ADD_ADDITIONAL_SUBJECT, ADD_ASSIGN_ADDITIONAL_SUBJECT, DELETE_ADDITIONAL_SUBJECT, DELETE_ASSIGN_ADDITIONAL_SUBJECT, GET_ADDITIONAL_SUBJECT, GET_ASSIGN_ADDITIONAL_SUBJECT } from "../../ActionTypes";


const InitialState = {
    additionalSubject: [],
    assignAdditionalSubjectList: [],
}

const ExamReducer = (state = InitialState, action) => {
    switch (action.type) {
        case DELETE_ADDITIONAL_SUBJECT:
            return {
                ...state,
                additionalSubject: state.additionalSubject.filter(
                    exam => exam._id !== action.payload
                ),
            };
        case ADD_ADDITIONAL_SUBJECT:
            return {
                ...state,
                additionalSubject: [...state.additionalSubject, ...action.payload]
            };
        case GET_ADDITIONAL_SUBJECT:
            return {
                ...state,
                additionalSubject: action.payload,
            };
        case DELETE_ASSIGN_ADDITIONAL_SUBJECT:
            return {
                ...state,
                assignAdditionalSubjectList: state.assignAdditionalSubjectList.filter(
                    exam => exam._id !== action.payload
                ),
            };
        case ADD_ASSIGN_ADDITIONAL_SUBJECT:
            return {
                ...state,
                assignAdditionalSubjectList: [...state.assignAdditionalSubjectList, ...action.payload]
            };
        case GET_ASSIGN_ADDITIONAL_SUBJECT:
            return {
                ...state,
                assignAdditionalSubjectList: action.payload,
            };

        default: return state
    }
}

export default ExamReducer;