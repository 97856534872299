//User Actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const USER_PROFILE = 'USER_PROFILE';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';


// states list action
export const SET_STATES_LIST = 'SET_STATES_LIST';

// set session action
export const SET_SCHOOL_SESSION = 'SET_SCHOOL_SESSION';
export const SET_SESSION_LIST = 'SET_SESSION_LIST';

export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export const SET_SECTIONS_LIST = 'SET_SECTIONS_LIST';
export const SET_ALERT = "SET_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";

// Dashboard Info 

export const FETCH_DASHBOARD_INFO_REQUEST = 'FETCH_DASHBOARD_INFO_REQUEST';
export const FETCH_DASHBOARD_INFO_SUCCESS = 'FETCH_DASHBOARD_INFO_SUCCESS';
export const FETCH_DASHBOARD_INFO_FAILURE = 'FETCH_DASHBOARD_INFO_FAILURE';

export const FETCH_FEE_INFO_REQUEST = 'FETCH_FEE_INFO_REQUEST';
export const FETCH_FEE_INFO_SUCCESS = 'FETCH_FEE_INFO_SUCCESS';
export const FETCH_FEE_INFO_FAILURE = 'FETCH_FEE_INFO_FAILURE';

// School Info 

export const SET_SCHOOL_INFO = 'SET_SCHOOL_INFO';
export const POST_SCHOOL_INFO = 'POST_SCHOOL_INFO';
export const PUT_SCHOOL_INFO = 'PUT_SCHOOL_INFO';
export const SET_SCHOOL_INFO_ERROR = 'SET_SCHOOL_INFO_ERROR';

export const SET_EXAM_PATTERN = "SET_EXAM_PATTERN";
export const SET_TERM_DATA = "SET_TERM_DATA";
export const SET_EXAM_DATA = "SET_EXAM_DATA";
export const SET_SUB_EXAM_DATA = "SET_SUB_EXAM_DATA";

export const GET_EXAM_PATTERN_ON_BASIS_SESSION = "GET_EXAM_PATTERN_ON_BASIS_SESSION";
export const GET_TERM_ON_BASIS_EXAM_PATTERN = "GET_TERM_ON_BASIS_EXAM_PATTERN";
export const GET_EXAM_ON_BASIS_TERM = "GET_EXAM_ON_BASIS_TERM";
export const GET_SUB_EXAM_ON_BASIS_EXAM = "GET_SUB_EXAM_ON_BASIS_EXAM";
export const GET_CLASS_SECTION_MAPPING_EXAM_SCHEDULE = "GET_CLASS_SECTION_MAPPING_EXAM_SCHEDULE"
export const SUBMIT_EXAM_SCHEDULE = "SUBMIT_EXAM_SCHEDULE";
export const GET_EXAM_SCHEDULE = "GET_EXAM_SCHEDULE";
export const DELETE_EXAM_SCHEDULE = "DELETE_EXAM_SCHEDULE";

export const ADD_ADDITIONAL_SUBJECT = "ADD_ADDITIONAL_SUBJECT";
export const DELETE_ADDITIONAL_SUBJECT = "DELETE_ADDITIONAL_SUBJECT";
export const UPDATE_ADDITIONAL_SUBJECT = "UPDATE_ADDITIONAL_SUBJECT";
export const GET_ADDITIONAL_SUBJECT = "GET_ADDITIONAL_SUBJECT";

export const GET_ASSIGN_ADDITIONAL_SUBJECT = "GET_ASSIGN_ADDITIONAL_SUBJECT";
export const ADD_ASSIGN_ADDITIONAL_SUBJECT = "ADD_ASSIGN_ADDITIONAL_SUBJECT";
export const GET_CLASS_SECTION_EXAM = "GET_CLASS_SECTION_EXAM";
export const GET_SUBJECT_TIME_TABLE_LIST = "GET_SUBJECT_TIME_TABLE_LIST";
export const GET_EXAM_SCHEDULE_VIEW = "GET_EXAM_SCHEDULE_VIEW";
export const GET_ADMIT_CARD = "GET_ADMIT_CARD";
export const EXAM_TIMETABLE_POST = "EXAM_TIMETABLE_POST";
export const GET_EXAM_TIMETABLE_LIST = "GET_EXAM_TIMETABLE_LIST";
export const VIEW_EXAM_TIMETABLE_LIST = "VIEW_EXAM_TIMETABLE_LIST";
export const DELETE_EXAM_TIMETABLE_LIST = "DELETE_EXAM_TIMETABLE_LIST";
export const GET_SUB_EXAM_SCHEDULE = "GET_SUB_EXAM_SCHEDULE";
export const FETCH_EXAM_SCHEDULE = "FETCH_EXAM_SCHEDULE";
export const GET_DESK_SLIP = "GET_DESK_SLIP";
export const GET_MARK_ALLOCATION_EXAM = "GET_MARK_ALLOCATION_EXAM";
export const GET_MARK_ALLOCATION_SUB_EXAM = "GET_MARK_ALLOCATION_SUB_EXAM";
export const GET_MARK_ALLOCATION_SUBJECT = "GET_MARK_ALLOCATION_SUBJECT";
export const GET_MARK_ALLOCATION_STUDENT = "GET_MARK_ALLOCATION_STUDENT";
export const CREATE_MARK_ALLOCATION = "CREATE_MARK_ALLOCATION";
export const GET_MARK_ALLOCATION_EXAM_LIST = "GET_MARK_ALLOCATION_EXAM_LIST";
export const DELETE_ASSIGN_ADDITIONAL_SUBJECT = "DELETE_ASSIGN_ADDITIONAL_SUBJECT";
export const EDIT_MARK_ALLOCATION_STUDENT = "EDIT_MARK_ALLOCATION_STUDENT";
export const FETCH_CLASS_SUBJECT_LIST_REQUEST = 'FETCH_CLASS_SUBJECT_LIST_REQUEST';
export const FETCH_CLASS_SUBJECT_LIST_SUCCESS = 'FETCH_CLASS_SUBJECT_LIST_SUCCESS';
export const FETCH_CLASS_SUBJECT_LIST_FAILURE = 'FETCH_CLASS_SUBJECT_LIST_FAILURE';
export const FETCH_ALL_SUBJECT_MARK_ALLOCATION = "FETCH_ALL_SUBJECT_MARK_ALLOCATION";

export const GET_MARK_ALLOCATION_TERM = "GET_MARK_ALLOCATION_TERM";
export const GET_NON_EXAM_LIST = "GET_NON_EXAM_LIST";
export const POST_NON_EXAM_LIST = "POST_NON_EXAM_LIST";
export const UPDATE_NON_EXAM_LIST = "UPDATE_NON_EXAM_LIST";
export const DELETE_NON_EXAM_LIST = "DELETE_NON_EXAM_LIST";
export const INSERT_NON_EXAM_MARK = "INSERT_NON_EXAM_MARK";
export const UPDATE_NON_EXAM_MARK = "UPDATE_NON_EXAM_MARK";
export const FETCH_NON_EXAM_MARK = "FETCH_NON_EXAM_MARK";
export const GET_SUBJECT_NON_EXAM = "GET_SUBJECT_NON_EXAM";
export const GET_EXAMTIMETABLE_CLASS = "GET_EXAMTIMETABLE_CLASS";
export const GET_EXAMTIMETABLE_SECTION = "GET_EXAMTIMETABLE_SECTION";
export const GET_TERM_EXAM_TIMETABLE = "GET_TERM_EXAM_TIMETABLE";
export const GET_MARKS_CLASS_VISE = "GET_MARKS_CLASS_VISE";
export const POST_MARKS_CLASS_VISE = "POST_MARKS_CLASS_VISE";
export const TEMPLATE_LIST_CLASS = "TEMPLATE_LIST_CLASS";
export const ADD_TEMPLATE_MAPPING_CLASS = "ADD_TEMPLATE_MAPPING_CLASS";
export const TEMPLATE_MAPPING_LIST = "TEMPLATE_MAPPING_LIST";
export const TEMPLATE_DELETE = "TEMPLATE_DELETE";
export const TEMPLATE_VIEW = "TEMPLATE_VIEW";
export const TEMPLATE_SETTING_LIST = "TEMPLATE_SETTING_LIST";
export const FETCH_EXAM_REPORT_DATA = "FETCH_EXAM_REPORT_DATA";
export const GET_EXAM_REPORT_DATA_LIST = "GET_EXAM_REPORT_DATA_LIST";
export const GET_TERM_REPORT_DATA_LIST = "GET_TERM_REPORT_DATA_LIST";
export const FETCH_STUDENT_REMARK = "FETCH_STUDENT_REMARK";
export const GET_MAPPING_STUDENT_REMARK_LIST = "GET_MAPPING_STUDENT_REMARK_LIST";
export const TERM_ATTENDANCE_LIST = "TERM_ATTENDANCE_LIST";
export const EXAM_ATTENDANCE_LIST = "EXAM_ATTENDANCE_LIST";
export const EDIT_TERM_ATTENDANCE_LIST = "EDIT_TERM_ATTENDANCE_LIST";
export const MARKSHEET_DATA_LIST = "MARKSHEET_DATA_LIST";
export const CROSS_LIST_DATA = "CROSS_LIST_DATA";
