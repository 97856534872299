const { START_LOADER, STOP_LOADER } = require("../../ActionTypes")

const InitialState = {
    isLoading : false
}

const LoaderReducer = (state = InitialState, action) => {
    switch(action.type) {
        case START_LOADER : return {
            ...state,
            isLoading: true
        }
        case STOP_LOADER : return {
            ...state,
            isLoading: false
        }
        default : return state
    }
}

export default LoaderReducer;