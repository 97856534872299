import React, { useState, useEffect, useCallback ,useRef} from "react";
import {
  UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, NavItem,
  NavLink, Button
} from "reactstrap";
import * as Icon from "react-feather";
import axios from "axios";
import { logoutUser } from '../../../store/actions/LoginAction';
import PropTypes from 'prop-types';
import classnames from "classnames";
import { connect } from 'react-redux';
import GlobalSearchAutoComplete from "../../../components/globalSearchAutoComplete/GlobalSearchAutoCompleteComponent";
import { history } from '../../../history';
import { SUB_ADMIN_ROUTES } from "../../../utils/constants/RouterConstants";

const NavbarUser = ({ user, userName, userImg, userRole, logoutUser, handleAppOverlay, suggestions }) => {
  const [navbarSearch, setNavbarSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [userInput, setUserInput] = useState('');
  
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    setSearch(path);
  }, []);

  const handleNavbarSearch = useCallback(() => {
    setNavbarSearch(prev => !prev);
  }, []);

  const logout = useCallback(async () => {
    await logoutUser();
    history.push('/');
  }, [logoutUser]);

  const viewDetails = useCallback((id, type) => {
    const url = type.toLowerCase() === 'student'
      ? `/${userRole}${SUB_ADMIN_ROUTES.STUDENT_VIEW}?studentId=${id}`
      : `/${userRole}${SUB_ADMIN_ROUTES.TEACHER_VIEW}?teacherId=${id}`;
    window.open(url, '_blank');
  }, [userRole]);

  const renderCustomSuggestion = useCallback(({ value, index, type }) => (
    <tbody key={`${type}-${index}`}>
      {type.toLowerCase() === 'student' ? (
        <tr>
          <td>{value.admissionNumber || '-'}</td>
          <td>{value.fullName || '-'}</td>
          <td>
            {value?.classId?.name
              ? (value?.sectionId?.name ? `${value.classId.name} - ${value.sectionId.name}` : '-')
              : '-'
            }
          </td>
          <td>{value?.parentInfo?.father?.name || '-'}</td>
          <td>{value?.parentInfo?.mother?.name || '-'}</td>
          <td>{value.mobileNumber || '-'}</td>
          <td>{type}</td>
          <td>
            <Button type="button" onClick={() => viewDetails(value._id, type)} color="success">View</Button>
          </td>
        </tr>
      ) : (
        <tr>
          <td>{`${value?.employeeCode?.prefix || ''}${value?.employeeCode?.number || ''}${value?.employeeCode?.suffix || ''}`}</td>
          <td>{value.fullName}</td>
          <td>{value.fatherName || '-'}</td>
          <td>{value.mobileNumber || '-'}</td>
          <td>{value.designation || '-'}</td>
          <td>{type}</td>
          <td>
            <Button type="button" onClick={() => viewDetails(value._id, type)} color="success">View</Button>
          </td>
        </tr>
      )}
    </tbody>
  ), [viewDetails]);

  const handleSuggestionsShown = useCallback((input) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
  
    if (navbarSearch && input && input.length > 2 && input !== userInput) {
      debounceTimeoutRef.current = setTimeout(() => {
        setUserInput(input);
        handleAppOverlay(input);
      }, 1000);
    }
  }, [navbarSearch, userInput, handleAppOverlay]);

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      {user.role !== "SuperAdmin" && user.role !== "Admin" && (
        <NavItem className="nav-search" onClick={handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" style={{ color: "blue" }} />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: navbarSearch,
              "d-none": !navbarSearch
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={21} className="primary" style={{ color: "#e0dfe8" }} />
            </div>
            <GlobalSearchAutoComplete
              className="form-control"
              suggestions={suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore Wyc..."
              autoFocus={true}
              clearInput={navbarSearch}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  setNavbarSearch(false);
                  handleAppOverlay("");
                }
              }}
              customRender={renderCustomSuggestion}
              onSuggestionsShown={handleSuggestionsShown}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                style={{ color: "white" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setNavbarSearch(false);
                  handleAppOverlay("");
                }}
              />
            </div>
          </div>
        </NavItem>
      )}
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {userName || user?.role}
            </span>
            <span className="user-status">({user?.mobileNumber})</span>
          </div>
          <span data-tour="user">
            <img
              src={user?.avatar || userImg}
              className="round"
              height="40"
              width="40"
              alt="avatar"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="/profile">
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle">Edit Profile</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem href='/' onClick={logout}>
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">Log Out</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </ul>
  );
};

NavbarUser.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  userName: PropTypes.string,
  userImg: PropTypes.string,
  userRole: PropTypes.string,
  handleAppOverlay: PropTypes.func.isRequired,
  suggestions: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.login?.users,
  localSessionId: state.SchoolReducer.localSessionId
});

export default connect(mapStateToProps, { logoutUser })(NavbarUser);