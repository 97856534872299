import { getKeyFromToken, TOKEN, TOKEN_PERMISSION_KEY, TOKEN_ROLE_KEY } from '../../utils/localStorageConstants';
import * as actionTypes from '../ActionTypes';

const initialState = {
    token: getKeyFromToken(TOKEN),
    userRole: getKeyFromToken(TOKEN_ROLE_KEY),
    userPermission: getKeyFromToken(TOKEN_PERMISSION_KEY),
    users: [],
    loading: undefined,
    redirect: ''
};

export const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                token: getKeyFromToken(TOKEN),
                userRole: getKeyFromToken(TOKEN_ROLE_KEY),
                redirect: action.payload
            };
        }

        case actionTypes.USER_PROFILE: {
            return {
                ...state,
                users: action.payload.user,
                userRole: localStorage.getItem('userRole'),
                loading: false
            };
        }

        case actionTypes.FETCH_USER_PROFILE: {
            return {
                ...state,
                loading: true
            };
        }

        case actionTypes.LOG_OUT: {
            return {
                ...initialState
            };
        }

        default:
            return state
    }
}
