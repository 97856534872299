//User Actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const USER_PROFILE = 'USER_PROFILE';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';


// states list action
export const SET_STATES_LIST = 'SET_STATES_LIST';

// set session action
export const SET_SCHOOL_SESSION = 'SET_SCHOOL_SESSION'; 
export const SET_SESSION_LIST = 'SET_SESSION_LIST';

export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export const SET_SECTIONS_LIST = 'SET_SECTIONS_LIST';

// Dashboard Info 

export const FETCH_DASHBOARD_INFO_REQUEST = 'FETCH_DASHBOARD_INFO_REQUEST';
export const FETCH_DASHBOARD_INFO_SUCCESS = 'FETCH_DASHBOARD_INFO_SUCCESS';
export const FETCH_DASHBOARD_INFO_FAILURE = 'FETCH_DASHBOARD_INFO_FAILURE';

export const FETCH_FEE_INFO_REQUEST = 'FETCH_FEE_INFO_REQUEST';
export const FETCH_FEE_INFO_SUCCESS = 'FETCH_FEE_INFO_SUCCESS';
export const FETCH_FEE_INFO_FAILURE = 'FETCH_FEE_INFO_FAILURE';

// School Info 

export const SET_SCHOOL_INFO = 'SET_SCHOOL_INFO';
export const POST_SCHOOL_INFO = 'POST_SCHOOL_INFO';
export const PUT_SCHOOL_INFO = 'PUT_SCHOOL_INFO';
export const SET_SCHOOL_INFO_ERROR = 'SET_SCHOOL_INFO_ERROR';


export const ADD_ADDITIONAL_SUBJECT = "ADD_ADDITIONAL_SUBJECT";
export const DELETE_ADDITIONAL_SUBJECT = "DELETE_ADDITIONAL_SUBJECT";
export const UPDATE_ADDITIONAL_SUBJECT = "UPDATE_ADDITIONAL_SUBJECT";
export const GET_ADDITIONAL_SUBJECT = "GET_ADDITIONAL_SUBJECT";

export const GET_ASSIGN_ADDITIONAL_SUBJECT = "GET_ASSIGN_ADDITIONAL_SUBJECT";
export const ADD_ASSIGN_ADDITIONAL_SUBJECT = "ADD_ASSIGN_ADDITIONAL_SUBJECT";
export const DELETE_ASSIGN_ADDITIONAL_SUBJECT = "DELETE_ASSIGN_ADDITIONAL_SUBJECT";