import { ROLES_BY_NAME } from "./constants/Roles";


export const PERMISSION_KEYS = {
  "dashboardEmployee": "dashboardEmployee",
  "dashboardSubAdmin": "dashboardSubAdmin",
  "schoolSettings": "schoolSettings",
  "manageSubAdminUsers": "manageSubAdminUsers",
  "studentRegistration": "studentRegistration",
  "studentInfo": "studentInfo",
  "teacherInfo": "teacherInfo",
  "classSettings": "classSettings",
  "studentAttendance": "studentAttendance",
  "teacherAttendance": "teacherAttendance",
  "leaveManagement": "leaveManagement",
  "homework": "homework",
  "timeTable": "timeTable",
  "subAdminCommunication": "subAdminCommunication",
  "calendar": "calendar",
  "syllabus": "syllabus",
  "diary": "diary",
  "exam": "exam",
  "fee": "fee",
  "transport": "transport",
  "income": "income",
  "expenditure": "expenditure",
  "inventory": "inventory",
  "gallery": "gallery",
  "certificates": "certificates",

  "dashboardSuperAdmin": "dashboardSuperAdmin",
  "manageSuperAdminUsers": "manageSuperAdminUsers",
  "schoolRequests": "schoolRequests",
  "createSchools": "createSchools",
  "superAdminCommunication": "superAdminCommunication",
  "bulkUpload": "bulkUpload",
  "superAdminReports": "superAdminReports",



  "dashboardAdmin": "dashboardAdmin",
  "manageSchools": "manageSchools",
  "adminCommunication": "adminCommunication",

}

const ADMIN_TABS = [
  "dashboardAdmin",
  "manageSchools",
  "adminCommunication",
]





const SUPER_ADMIN_TABS = [
  "dashboardSuperAdmin",
  "manageSuperAdminUsers",
  "schoolRequests",
  "createSchools",
  "superAdminCommunication",
  "superAdminReports"
];

const SUB_ADMIN_TABS = [
  "dashboardSubAdmin",
  "schoolSettings",
  "manageSubAdminUsers",
  "studentRegistration",
  "studentInfo",
  "teacherInfo",
  "classSettings",
  "studentAttendance",
  "teacherAttendance",
  "leaveManagement",
  "homework",
  "timeTable",
  "subAdminCommunication",
  "calendar",
  "syllabus",
  "diary",
  "exam",
  "fee",
  "transport",
  "income",
  "expenditure",
  "inventory",
  "gallery",
  "certificates",
];

export const SubsciptionByName = {
  "dashboardEmployee": "Dashboard Employee",
  "dashboardSubAdmin": "Dashboard SubAdmin",
  "schoolSettings": "School Settings",
  "manageSubAdminUsers": "Manage Users",
  "studentRegistration": "Student Registration",
  "studentInfo": " Student Info",
  "teacherInfo": " Teacher Info",
  "classSettings": "Class Settings",
  "studentAttendance": "Student Attendance",
  "teacherAttendance": " Teacher Attendance",
  "leaveManagement": "Leave Management",
  "homework": "Homework",
  "timeTable": "Time Table",
  "subAdminCommunication": "Communication",
  "calendar": "Calendar",
  "syllabus": "Syllabus",
  "diary": "Diary",
  "exam": "Exam",
  "fee": "Fee",
  "transport": "Transport",
  "income": "Income",
  "expenditure": "Expenditure",
  "inventory": "Inventory",
  "gallery": "Gallery",
  "certificates": "Certificates"
}

const EMPLOYEE_TABS = [...SUB_ADMIN_TABS];
EMPLOYEE_TABS[0] = "dashboardEmployee";


function setPermisssionOrder(permission, role) {
  if (permission && permission.length) {
    if (role === 'SuperAdmin') {
      return setSuperAdminOrder(permission);
    }
    else if (role === 'SubAdmin' || role === ROLES_BY_NAME.Employee || role === "Principal") {
      return setSubAdminOrder(permission);
    }
    else if (role === 'Admin' || role === ROLES_BY_NAME.Employee || role === "Principal") {
      return setAdminOrder(permission);
    }
    else {
      return permission;
    }
  }
}

function setSuperAdminOrder(permission) {
  let order = [], i = 0;
  SUPER_ADMIN_TABS.forEach((item) => {
    if (permission?.includes(item)) {
      order[i] = item;
      i++;
    }
  })
  return order;
}

function setAdminOrder(permission) {
  let order = [], i = 0;
  ADMIN_TABS.forEach((item) => {
    if (permission?.includes(item)) {
      order[i] = item;
      i++;
    }
  })
  return order;
}

function setSubAdminOrder(permission) {
  let order = [], i = 0;
  SUB_ADMIN_TABS.forEach((item) => {
    if (permission?.includes(item)) {
      order[i] = item;
      i++;
    }
  })
  if (permission?.includes('dashboardEmployee')) {
    if (order?.includes('dashboardSubAdmin')) {
      order[order.indexOf('dashboardSubAdmin')] = 'dashboardEmployee';
    }
    else {
      order.splice(0, 0, 'dashboardEmployee');
    }
  }
  return order;
}

export {
  SUPER_ADMIN_TABS,
  ADMIN_TABS,
  SUB_ADMIN_TABS,
  EMPLOYEE_TABS,
  setPermisssionOrder
};
