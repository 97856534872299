import Axios from "axios";
import { Filter } from "react-feather";

const baseURL = process.env.REACT_APP_SERVICE_URL;
//const baseURL = "http://35.154.75.214:3001/api/";
export default class API {
  //static baseURL = "http://35.154.75.214:3001/api/";

  static request(url, method, body = null) {
    const access_token = localStorage.getItem("token");
    return fetch(`${baseURL + url}`, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + (access_token ? access_token : null),
      },
      body: body === null ? null : JSON.stringify(body),
    });
  }

  static loginUser(body) {
    return this.request("auth/login", "POST", body);
  }

  static patchAuthForgotPassword(body) {
    return this.request("auth/forgot-password", "PATCH", body);
  }

  static patchAuthResetPassword(body) {
    return this.request("auth/reset-password", "PATCH", body);
  }

  static getUserInfo() {
    return this.request("users/me", "GET");
  }

  static updateUserInfo(body) {
    return this.request("users/me", "PUT", body);
  }

  static getStudentsList(sessionId, filter = null) {
    let url = `student/_search?filter={"sessionId":{"$eq": "${sessionId}"}}&start=0&count=-1`;
    if (filter) {
      url = `student/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static promoteList(body) {
    let url = `student/promote-list`;
    return this.request(url, "POST", body);
  }

  static getStudentsByFields(sessionId, filter = null, fields = null, populate = null) {

    let url = `student/_search2?filter={"sessionId":{"$eq": "${sessionId}"}}&start=0&count=-1`;

    if (filter) {
      url = `student/_search2?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }

    if (fields) {
      url += `&fields=${fields.join(',')}`;;
    }

    if (populate) {
      url += `&populate=${populate.join(',')}`;;
    }

    return this.request(url, "GET");
  }

  static getStudentList(filter) {
    let url = `student/student-list?filter=${JSON.stringify(
      filter
    )}`
    return this.request(url, "GET")
  }

  static getStaffList() {
    let url = `users/staffList`
    return this.request(url, "GET")
  }

  static getStudentStaff(_id) {
    //  let url=`student/_getStaff/${_id}`
    return this.request(`student/_getStaff/${_id}`, "GET")
  }

  static getStudentDateRange(body) {
    let url = `fee-payment/dateRangeReport`
    return this.request(url, "POST", body)
  }

  static getTransportDiscountReport(body) {
    let url = `transport-payment/discountReport`
    return this.request(url, "POST", body)
  }

  static getTransportallocationReport(body) {
    let url = `transport-payment/allocationReport`
    return this.request(url, "POST", body)
  }

  static getStudentAdmissionList(filter) {
    let url = `admission/_search?&start=0&count=-1&filter=${JSON.stringify(
      filter
    )}&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postStudentBirthdaymsg(body) {
    let url = `communication/_birthDay`
    return this.request(url, "POST", body)
  }



  // admission -----> admission Report
  static getStudentAdmissionReport(filter) {
    let url = `admission/getAdmissionReport?&start=0&count=-1&filter=${JSON.stringify(
      filter
    )}&sort=-createdAt`;
    return this.request(url, "GET");
  }


  // admission -----> Registration Report
  static getRegisterStudentRecord(filter) {
    let url = `admission/getRegistrationReport?&start=0&count=-1&filter=${JSON.stringify(
      filter
    )}&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getRegisterRecords(sessionId, filter = null) {
    let url = `registration-setting/_search?&start=0&count=-1&filter={"sessionId":{"$eq":"${sessionId}"}}&sort=-createdAt`;
    if (filter) {
      url = `registration-setting/_search?filter=${JSON.stringify(filter)}`;
    }
    return this.request(url, "GET");
  }

  static getRegisterSetting(filter) {
    let url = `registration-setting/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postStudentAdmissionRecord(body) {
    return this.request(`admission/_create`, "POST", body);
  }

  static patchStudentAdmissionPayment(body, _id) {
    return this.request(`admission/offline-payment/${_id}`, "PATCH", body);
  }

  static putStudentAdmissionRecord(body, _id) {
    return this.request(`admission/_update/${_id}`, "PUT", body);
  }

  static postRegisterStudentRecord(body) {
    return this.request(`registration-setting/_create`, "POST", body);
  }

  static deleteRegisterStudent(id) {
    return this.request(`admission/_delete/${id}`, "DELETE").then((resp) =>
      resp.json()
    );
  }

  static deleteExamSchedule(id) {
    return this.request(`registration-setting/_delete/${id}`, "DELETE");
  }

  static putExamSchedule(id, body) {
    let url = `registration-setting/_update/${id}`;
    return this.request(url, "PUT", body);
  }

  static getStudentAdmissionById(_id) {
    return this.request(
      `admission/_search?filter={"_id":{"$eq":"${_id}"}}`,
      "GET"
    );
  }

  static createStudentsAttendance(body) {
    let url = `student-attendance/_create`;
    return this.request(url, "POST", body);
  }

  static getUserSchoolInfo(fields = "", filter = "") {
    if (fields !== "") {
      return this.request(`school/me?fields=${fields}&start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`, "GET");
    }
    if (filter !== "") {
      return this.request(`school/me?&start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`, "GET");
    }
    return this.request(`school/me?&start=0&count=-1&sort=-createdAt`, "GET");
  }


  static updateSchool(body) {
    return this.request("school/update-school", "PUT", body).then((resp) =>
      resp.json()
    );
  }

  static getSuperAdminUsers(params) {
    let url = "users/_search?&start=0&count=-1&sort=firstName";
    if (params?.filter) {
      url += `&filter=${params?.filter}`;
    } else {
      url += `&filter={"role":{"$in":["SuperAdmin", "Admin", "SubAdmin"]}}`;
    }
    if (params?.fields) {
      url += `&fields=${params?.fields}`;
    }
    return this.request(url, "GET");
  }

  static getSubAdminUsers(filter) {
    let url = "users/sub-admin/_search?&start=0&count=-1&sort=firstName";
    if (filter) {
      url = `users/sub-admin/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=firstName`;
    }
    return this.request(url, "GET");
  }

  static getSubAdminUser(_id) {
    return this.request(
      `users/sub-admin/_search?filter={"_id":{"$eq": "${_id}"}}`,
      "GET"
    );
  }

  static getSchoolAdminUsers(_id) {
    return this.request(
      `users/_search?filter={"_id":{"$eq": "${_id}"}}&field=_id,fullName,avatar`,
      "GET"
    );
  }

  static getSchoolDetailList(params) {
    let url = "school/_search?start=0&count=-1&sort=name";
    if (params?.filter) {
      url += `&filter=${params.filter}`;
    }
    if (params?.fields) {
      url += `&fields=${params?.fields}`;
    }
    return this.request(url, "GET");
  }

  static getSchoolDetailList2(params) {
    let url = "school/school-search?start=0&count=-1&sort=name";
    if (params?.filter) {
      url += `&filter=${params.filter}`;
    }
    if (params?.fields) {
      url += `&fields=${params?.fields}`;
    }
    return this.request(url, "GET");
  }

  static getRenewSubscription(_id) {
    return this.request(
      `school/_search?filter={"_id":{"$eq": "${_id}"}}&field=subscriptionDetails&sort=-createdAt`,
      "GET"
    );
  }

  static getSchoolRequestRecord(_id) {
    return this.request(
      `request-school/_search?filter={"_id":{"$eq": "${_id}"}}&sort=-createdAt`,
      "GET"
    );
  }

  static getScheduleExamInfo() {
    return this.request("school/schedule-exam", "GET");
  }

  static patchScheduleEntranceExam(body) {
    return this.request("school/schedule-exam", "PATCH", body);
  }

  static getPermissions() {
    return this.request("permission/me", "GET");
  }

  static postUserRecord(body) {
    return this.request("users/school-admin/_create", "POST", body);
  }

  static putUserRecord(_id, body) {
    return this.request(`users/school-admin/_update/${_id}`, "PUT", body);
  }

  static postClassRegisterationFee(body) {
    return this.request("school/set-fee", "PATCH", body);
  }

  static postStudentRecord(body) {
    return this.request("student/_create", "POST", body);
  }

  static putStudentRecord(body, _id) {
    return this.request(`student/_update/${_id}`, "PUT", body);
  }

  static patchStudentRecord(body, _id) {
    return this.request(`student/deactivate/${_id}`, "PATCH", body);
  }

  static patchStudentClassSectionUpdate(body, _id) {
    return this.request(`student/class-section/${_id}`, "PATCH", body);
  }

  static patchStudentClassUpdate(body) {
    return this.request(`student/_changeClass`, "PUT", body);
  }

  static getBulkPayment(id, filter) {
    let url = 'student-feeBulk-payment/_search';
    if (id) {
      const encodedFilter = encodeURIComponent(`{"studentId":{"$eq":"${id}"}}`);
      url = `student-feeBulk-payment/_search?filter=${encodedFilter}`;
    } if (filter) {
      return this.request(`student-feeBulk-payment/_search?filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`, "GET");
    }
    return this.request(url, 'GET');
  }

  static patchStudentFeeCategoryUpdate(body) {
    return this.request(`student/_change-feeCategory`, "PUT", body);
  }

  static validateStudentPhone(id) {
    return this.request(`student/validate/${id}`, "GET").then((resp) =>
      resp.json()
    );
  }

  static putUserRecordDetails(body) {
    return this.request("users/me", "PUT", body);
  }

  static putSchoolDetailUpdate(body, _id) {
    return this.request(`school/_update/${_id}`, "PUT", body);
  }

  static patchSchoolRenew(body, _id) {
    return this.request(`school/renew-subscription/${_id}`, "PATCH", body);
  }

  static patchMessageAllotment(body, _id) {
    return this.request(`school/sms/${_id}/${body.allotment}`, "PATCH", body);
  }

  static addBiomtericDevice(body) {
    return this.request("school/biometric/add-biometric", "POST", body);
  }

  static getBiomtericDevice() {
    return this.request("school/biometric/_search", "GET");
  }

  static addMessageAllotement(body) {
    return this.request("school/message-allotment/add", "POST", body);
  }

  static getMessageAllotement() {
    return this.request("school/message-allotment/_search", "GET");
  }

  static patchBiomtericDevice(body, _id) {
    return this.request(
      `school/biometric/update-biometric/${_id}`,
      "PUT",
      body
    );
  }

  static patchUpdateBiometricDevice(body, schoolId, deviceId) {
    return this.request(
      `school/biometric-device/update/${schoolId}/${deviceId}`,
      "PATCH",
      body
    );
  }

  static patchSchoolSession(body, entity) {
    let url = "school/_setting";
    if (entity) {
      url += "/" + entity;
    }
    return this.request(url, "PATCH", body);
  }

  static patchSchoolInfo(body) {
    return this.request(`school/_update`, "PUT", body);
  }

  static postSchoolRecord(body) {
    delete body.session;
    return this.request("school/_create", "POST", body);
  }

  static getSchoolListing(filter) {
    return this.request(
      `request-school/_search?filter={"requestStatus":{"$eq":"${filter}"}}&start=0&count=-1&sort=-createdAt`,
      "GET"
    );
  }

  static getSuperAdminDashboardInfo() {
    return this.request("school/super-admin/dashboard", "GET");
  }

  static getAdminDashboardInfo() {
    return this.request("school/admin/dashboard", "GET");
  }

  static getAdminSchoolinfo(filter) {
    console.log("filter-------", filter)
    return this.request(
      `school/_search?filter=${filter}`,
      "GET"
    );
  }

  static getSubAdminDashboardInfo(userId) {
    return this.request(`school/sub-admin/dashboard/${userId}`, "GET");
  }

  static getDashboardFeeInfo(userId) {
    return this.request(`school/sub-admin/dashboard/fee/${userId}`, "GET");
  }

  static postSchoolPaymentRequest(userId, body) {
    return this.request(`request-school/send-email/${userId}`, "POST", body);
  }

  static postNormalUser(body) {
    return this.request("registration/_create", "POST", body);
  }

  static getS3SignedUrl(body) {
    return this.request("users/s3/get-pre-signed-url", "POST", body);
  }

  static getS3Signedstudent(body) {
    return this.request("student/s3/get-pre-signed-url", "POST", body);
  }

  static patchSchoolPaymentRequest(body, _id) {
    return this.request(`request-school/request-status/${_id}`, "PATCH", body);
  }

  static updateUserProfile(body, _id) {
    return this.request(`users/update/${_id}`, "PUT", body);
  }
  static getTeacherRecord(filter) {
    let url = "teacher/_search?start=0&count=-1&sort=-createdAt";
    if (filter) {
      url = `teacher/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }
  static getTeacherProfileById(_id) {
    return this.request(
      `teacher/_search?filter={"_id":{"$eq":"${_id}"}}`,
      "GET"
    );
  }

  static getTeacherSubject(filter) {
    let url = "subject-teacher-mapping/_search";
    if (filter) {
      url = `subject-teacher-mapping/_search?filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static putTeacherRecord(body, _id) {
    return this.request(`teacher/_update/${_id}`, "PUT", body);
  }
  static postTeacherRecord(body) {
    return this.request("teacher/_create", "POST", body);
  }
  static patchManageTeacher(_id, body) {
    let url = `teacher/deactivate/${_id}`;
    return this.request(url, "PATCH", body);
  }

  static patchManageUser(_id, status) {
    let url = `users/deactivate/${_id}/${status}`;
    return this.request(url, "PATCH");
  }

  static patchSchoolStatus(_id, status) {
    let url = `school/deactivate/${_id}/${status}`;
    return this.request(url, "PATCH");
  }

  // static deleteSchoolSettings(_id) {
  //   let url = `school/delete/${_id}`;
  //   return this.request(url, "DELETE");
  // }

  static getUserRecord(_id, fields) {
    let url = `users/_search?filter={"_id":{"$eq": "${_id}"}}`;
    if (fields) {
      url += `&fields=${fields}`;
    }
    return this.request(url, "GET");
  }

  static getSchoolRecord(_id) {
    return this.request(
      `school/_search?filter={"_id":{"$eq": "${_id}"}}`,
      "GET"
    );
  }

  static uploadWithSignedUrl(file, signedUrl, progressEventCallback) {
    return Axios.put(signedUrl, file, {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (
          progressEventCallback &&
          typeof progressEventCallback === "function"
        ) {
          progressEventCallback(percentCompleted);
        }
      },
      headers: {
        "Content-Type": file.type,
      },
    });
  }

  static patchSUperAdminUserMobileNumber(body, userId) {
    return this.request(`users/mobile-number/${userId}`, "PATCH", body);
  }
  static patchUserMobileNumber(body) {
    return this.request(`users/school-admin/mobile-number`, "PATCH", body);
  }

  static patchSubAdminUserMobileNumber(body, _id) {
    return this.request(`users/school-admin/mobile/${_id}`, "PATCH", body);
  }

  static patchStudentMobileNumber(body, _id) {
    return this.request(`student/mobile-number/${_id}`, "PATCH", body);
  }

  static patchTeacherMobileNumber(body, _id) {
    return this.request(`teacher/mobile-number/${_id}`, "PATCH", body);
  }

  static patchUserPasswordUpdate(body) {
    return this.request(`users/school-admin/update-password`, "PATCH", body);
  }

  static patchUserProfileImage(body, _id) {
    return this.request(`users/avatar/${_id}`, "PATCH", body);
  }

  static patchStudentProfileAndPerentImage(body, _id) {
    return this.request(`student/update-avatar/${_id}`, "PATCH", body);
  }

  static patchTeacherProfile(body, _id) {
    return this.request(`teacher/avatar/${_id}`, "PATCH", body);
  }

  static getS3SignedUrlSchool(body, key, _id) {
    return this.request(
      `school/s3/get-pre-signed-url/${key}/${_id}`,
      "POST",
      body
    );
  }

  static getS3SignedSchoolDocUrl(schoolId, body) {
    return this.request(
      `school/s3/get-pre-signed-url/documents/${schoolId}`,
      "POST",
      body
    ).then((resp) => resp.json());
  }

  static patchSchoolImages(body, key, _id) {
    return this.request(`school/school-images/${key}/${_id}`, "PATCH", body);
  }

  static postSchoolUser(body) {
    return this.request(`users/school-users/_create`, "POST", body);
  }

  static postEmployeeAttendance(body) {
    return this.request(`employee-attendance/_create`, "POST", body);
  }

  static patchEmployeeAttendance(body) {
    return this.request(`employee-attendance/out-time`, "PATCH", body);
  }

  static getEmployeeAttendance() {
    return this.request(`users/employeeList`, "GET");
  }

  static putSchoolUser(body, _id) {
    return this.request(`users/school-users/_update/${_id}`, "PUT", body);
  }

  static patchSchoolUser(_id, status) {
    return this.request(`users/deactivate/${_id}/${status}`, "PATCH");
  }

  static updateNewOrderClasses(body, entity) {
    return this.request(`school-setting/${entity}/update-order`, "PUT", body);
  }

  static getSchoolSettingByEntity(entity, schoolId, sessionId) {
    let url = `school-setting/${entity}/me?start=0&count=-1`;
    if (schoolId) {
      //url = `school-setting/${entity}/me?filter={"schoolId":"${schoolId}","currentSession":{"$eq": "true"}}`;
      url = `school-setting/present-session/${schoolId}`;
    }
    if (sessionId) {
      url = `school-setting/${entity}/me?start=0&count=-1&filter={"sessionId":{"$eq":"${sessionId}"}}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getClass(sessionId) {
    let url = `school-setting/class-setting/me`;
    if (sessionId) {
      //url = `school-setting/${entity}/me?filter={"schoolId":"${schoolId}","currentSession":{"$eq": "true"}}`;
      url = `school-setting/class-setting/me/${sessionId}`;
    }
    return this.request(url, "GET");
  }

  static getSection(sessionId) {
    let url = `school-setting/section-setting/me`;
    if (sessionId) {
      //url = `school-setting/${entity}/me?filter={"schoolId":"${schoolId}","currentSession":{"$eq": "true"}}`;
      url = `school-setting/section-setting/me/${sessionId}`;
    }
    return this.request(url, "GET");
  }

  static postSchoolSettingByEntity(entity, body, schoolId) {
    let url = `school-setting/${entity}/_create`;
    if (schoolId) {
      url = `school-setting/${entity}/create-present/${schoolId}`;
    }
    return this.request(url, "POST", body);
  }

  static putSchoolSettingByEntity(entity, id, body) {
    return this.request(`school-setting/${entity}/_update/${id}`, "PUT", body);
  }

  static deleteSchoolSettingByEntity(entity, id) {
    return this.request(`school-setting/${entity}/_delete/${id}`, "DELETE");
  }

  static puttityFromSuperAdmin(entity, body, schoolId) {
    let url = `school-setting/${entity}/update-present/${schoolId}`;
    return this.request(url, "PUT", body);
  }

  static getBoardList() {
    return this.request(`board/_search`, "GET");
  }

  static getClassSectionRecords(id) {
    return this.request(`class/me/${id}`, "GET");
  }

  static getClassSection(filter) {
    if (filter) {
      return this.request(
        `class/_search?filter=${JSON.stringify(filter)}&start=0&count=-1&sort=-createdAt`,
        "GET"
      ).then((resp) => resp.json());
    } else {
      return this.request(
        "class/_search?&start=0&count=-1&sort=-createdAt",
        "GET"
      ).then((resp) => resp.json());
    }
  }

  static getClassStudentData(filter) {
    let url = `class/_search?filter=${filter}`;
    return this.request(url, "GET");
  }

  static postClassSection(body) {
    return this.request("class/_create", "POST", body).then((resp) =>
      resp.json()
    );
  }

  static postDelegatedTeacher(body) {
    return this.request("delegate-teacher/_create", "POST", body);
  }

  static getDelegatedTeachers() {
    return this.request("delegate-teacher/_search", "GET");
  }

  static delegatedTeacherUpdate(body, id) {
    return this.request("delegate-teacher/_update/" + id, "PUT", body);
  }

  static deleteDelegatedTeacher(id) {
    return this.request("delegate-teacher/_delete/" + id, "DELETE");
  }

  static assignOptionalSubjects(body) {
    return this.request("student/_assignOptionalSubjects", "PUT", body);
  }

  static promoteStudent(body) {
    return this.request("student/_promoteStudents", "PUT", body);
  }

  static putClassSection(body, id) {
    return this.request("class/_update/" + id, "PUT", body).then((resp) =>
      resp.json()
    );
  }

  static deleteClassSection(id) {
    return this.request("class/_delete/" + id, "DELETE").then((resp) =>
      resp.json()
    );
  }

  static patchClassSectionGenrateRollNo(body) {
    return this.request(`class/generate-roll`, "PATCH", body);
  }

  static patchSubjectClassSection(id, body) {
    return this.request(`class/update-subject/${id}`, "PATCH", body).then(
      (resp) => resp.json()
    );
  }

  static getClassSubjectMapping(filter) {
    return this.request(
      "class-subject-mapping/_subject-available" + (filter ? "?filter=" + filter : ""),
      "GET"
    );
  }

  static getClassAllSubject(filter) {
    return this.request(
      "class-subject-mapping/_search" + (filter ? "?filter=" + filter : ""),
      "GET"
    );
  }

  static getStudentSubject(id) {
    return this.request("student/student-subject/" + id, "GET");
  }

  static postClassSubjectMapping(body) {
    return this.request("class-subject-mapping/_create", "POST", body).then(
      (resp) => resp.json()
    );
  }

  static deleteAssignSubjectMapping(id) {
    return this.request("class-subject-mapping/_delete/" + id, "DELETE");
  }

  static putClassSubjectMapping(body, id) {
    return this.request(`class-subject-mapping/_update/${id}`, "PUT", body);
  }

  static getSubjectTeacherMapping(filter = null) {
    let url = `subject-teacher-mapping/_search`;
    if (filter) {
      url += `?filter=${filter}`;
    }
    return this.request(url, "GET").then((resp) => resp.json());
  }

  static postSubjectTeacherMapping(body) {
    return this.request(`subject-teacher-mapping/_create`, "POST", body).then(
      (resp) => resp.json()
    );
  }

  static deleteSubjectTeacherMapping(id) {
    return this.request(`subject-teacher-mapping/_delete/${id}`, "DELETE").then(
      (resp) => resp.json()
    );
  }

  static putSubjectTeacherMapping(id, body) {
    return this.request(
      `subject-teacher-mapping/_update/${id}`,
      "PUT",
      body
    ).then((resp) => resp.json());
  }

  static getSectionByClass(filter) {
    return this.request(`class/_search?filter=${filter}`, "GET").then((resp) =>
      resp.json()
    );
  }

  static postPromoteStudent(body) {
    return this.request(`student/import`, "POST", body).then((resp) => resp.json());
  }

  static getStudentAttendance(sessionId, filter) {
    return this.request(
      `student-attendance/_search?filter=${filter}&start=0&count=-1&sort=-createdAt`,
      "GET"
    );
  }

  static putStudentAttendance(id, body) {
    return this.request(
      `student-attendance/mark-attendance/${id}`,
      "PATCH",
      body
    );
  }

  static deleteStudentAttendance(id) {
    return this.request(`student-attendance/_delete/${id}`, "DELETE");
  }

  static postStudentClassAttendance(body) {
    return this.request("student-attendance/class/report", "POST", body);
  }

  static postStudentAcctoClass(body) {
    return this.request("student-attendance/studentReport", "POST", body);
  }

  static postObjectAttendace(body) {
    return this.request("student-attendance/addBulkAttendance", "POST", body);
  }

  static postStudentBulkClass(body) {
    return this.request("student-attendance/getBulkAttendance", "POST", body);
  }

  static handleStuDataPost(body) {
    return this.request("student-attendance/addTermAttendance", "POST", body);
  }

  static patchSiblingInformation(id, body) {
    return this.request(`student/_addSibling/${id}`, "PATCH", body);
  }

  static patchStaffInformation(id, body) {
    return this.request(`student/_addStaff/${id}`, "PATCH", body);
  }

  static getSiblingInformation(id) {
    return this.request(`student/_getSibling/${id}`, "GET");
  }

  static deleteSibling(id, body) {
    return this.request(`student/_deleteSibling/${id}`, "PATCH", body);
  }

  static deleteStaff(id, body) {
    return this.request(`student/_deleteStaff/${id}`, "PATCH", body);
  }

  static postStudentAttendanceRecord(body) {
    return this.request("student-attendance/_create", "POST", body);
  }

  static patchSchoolAdmin(body, id) {
    return this.request(`school/admin/${id}`, "PATCH", body).then((resp) =>
      resp.json()
    );
  }

  static patchSchoolAdminUpdateSession(body, id) {
    return this.request(
      `school-setting/session-setting/update-present/${id}`,
      "PUT",
      body
    ).then((resp) => resp.json());
  }

  static patchSchoolSubAdmin(body, id) {
    return this.request(`school/sub-admin/${id}`, "PATCH", body).then((resp) =>
      resp.json()
    );
  }

  static patchRemoveSchoolAdmin(schoolId, userId) {
    return this.request(
      `school/admin/pull-user/${schoolId}/${userId}`,
      "PATCH"
    ).then((resp) => resp.json());
  }

  static patchRemoveSchoolSubAdmin(schoolId, userId) {
    return this.request(
      `school/sub-admin/pull-user/${schoolId}/${userId}`,
      "PATCH"
    ).then((resp) => resp.json());
  }

  static getTemplateList() {
    let url = "template/_search?start=0&count=-1";
    return this.request(url, "GET").then((resp) => resp.json());
  }

  static getReportTemplate(body) {
    let url = "communication/_send/due";
    return this.request(url, "POST", body)
  }

  static getTransportReportTemplate(body) {
    let url = "communication/_send/transport/due";
    return this.request(url, "POST", body)
  }

  static getSectionData(body) {
    let url = "class/section-specific";
    return this.request(url, "POST", body)
  }

  static createTemplate(body) {
    return this.request("template/_create", "POST", body).then((resp) =>
      resp.json()
    );
  }

  static sendComSuperAdmin(body, type) {
    return this.request(`user/communication`, "POST", body).then((resp) =>
      resp.json()
    );
  }

  static getDocSignedUrl(body, schoolId) {
    return this.request(
      `school/s3/get-pre-signed-url/documents/${schoolId}`,
      "POST",
      body
    );
  }

  static deleteSchool(schoolId) {
    return this.request(`school/_delete/${schoolId}`, "DELETE").then((resp) =>
      resp.json()
    );
  }

  static deleteUser(userId) {
    return this.request(`users/_delete/${userId}`, "DELETE").then((resp) =>
      resp.json()
    );
  }

  static updateSchoolDoc(schooId, body) {
    return this.request(
      `school/school-images/documents/${schooId}`,
      "PATCH",
      body
    );
  }

  static updateStudentDoc(studentId, body) {
    return this.request(`student/documentUpload/${studentId}`, "PATCH", body);
  }

  static updateTransportDoc(transportId, body) {
    return this.request(`transport-user/documentUpload/${transportId}`, "PATCH", body);
  }

  static updateVehicleDoc(vehicleId, body) {
    return this.request(`vehicle/documentUpload/${vehicleId}`, "PATCH", body);
  }

  static searchSchoolSettingByEntity(entity, filter) {
    let url = `school-setting/${entity}/_search`;
    if (filter) {
      url += `?filter=${JSON.stringify(filter)}`;
    }
    return this.request(url, "GET");
  }

  static searchClassSettingByEntity(filter) {
    let url = `school-setting/_searchClass`;
    if (filter) {
      url += `?filter=${JSON.stringify(filter)}`;
    }
    return this.request(url, "GET");
  }

  static studentBulkupload(payload) {
    const url = `student/bulk-upload`;
    return this.request(url, "POST", payload);
  }

  static avatarBulkupload(payload) {
    const url = `student/bulk-upload-avatar`;
    return this.request(url, "POST", payload);
  }

  static teacherBulkupload(payload) {
    const url = `teacher/bulk-upload`;
    return this.request(url, "POST", payload);
  }

  static getFeeConfig(entity, sessionId) {
    const url = `${entity}/_search?&start=0&count=-1&filter={"sessionId":{"$eq":"${sessionId}"}}&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static createFeeConfig(entity, payload) {
    const url = `${entity}/_create`;
    return this.request(url, "POST", payload);
  }

  static updateFeeConfig(entity, id, payload) {
    const url = `${entity}/_update/${id}`;
    return this.request(url, "PUT", payload);
  }

  static deleteFeeConfig(id) {
    return this.request(`fee-structure/_delete/${id}`, "DELETE");
  }

  static deleteFeeHead(id) {
    return this.request(`fee-head/_delete/${id}`, "DELETE");
  }

  static deleteFeeStructure(id) {
    return this.request(`fee-configure/_delete/${id}`, "DELETE");
  }
  static assignStructure(payload, sessionId) {
    const url = `school-setting/assign-structure?&start=0&count=-1&filter={"sessionId":{"$eq":"${sessionId}"}}&sort=-createdAt`;
    return this.request(url, "PATCH", payload);
  }

  static postCompleteFeePayment(body) {
    const url = `fee-payment/payFee`;
    return this.request(url, "POST", body)
  }

  static getAssignStructure(sessionId) {
    let url = `school-setting/assignedFeeStructures?&start=0&count=-1&filter={"sessionId":{"$eq":"${sessionId}"}}&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getDueFeeAmount(body) {
    let url = `fee-payment/student/dueAmount`;
    return this.request(url, "POST", body);
  }

  static getDueTransportAmount(body, filter) {
    let url = `transport-payment/dueTransportAmount?fields=${filter}`;
    return this.request(url, "POST", body);
  }

  static postCreateDiscount(payload) {
    const url = `discount/_create`;
    return this.request(url, "POST", payload);
  }

  static createGlobalDiscount(payload) {
    const url = `discount/global/_create`;
    return this.request(url, "POST", payload);
  }

  static deleteDiscount(id) {
    return this.request(`discount/_delete/${id}`, "DELETE")
  }

  static updateDiscount(id, body) {
    return this.request(`discount/_update/${id}`, "PUT", body)
  }

  static getDiscountStudentSearch(filter) {
    let url = `discount/_search?filter=${filter}&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getTermAttendance(filter) {
    let url = `student-attendance/getTermAttendance?filter=${filter}&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getTimeTableStructure(filter) {
    if (filter) {
      let url = `time-table-structure/_search?filter=${JSON.stringify(filter)}&start=0&count=-1&sort=-createdAt`;
      return this.request(url, "GET");
    }
    let url = `time-table-structure/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getEmpAttendance(filter) {
    let url = `employee-attendance/getEmpAttendance?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `employee-attendance/getEmpAttendance?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static updateEmpAttendance(body) {
    return this.request(`employee-attendance/_update`, "PATCH", body);
  }

  static postTimeTableStructure(payload) {
    const url = `time-table-structure/_create`;
    return this.request(url, "POST", payload);
  }

  static getTimeTable(filter) {
    let url = `time-table/_search?start=0&count=-1&filter=${JSON.stringify(
      filter
    )}`;
    return this.request(url, "GET");
  }

  static getTimeTableForTeacher(filter) {
    let url = `time-table/teacher/_lecture?start=0&count=-1&filter=${JSON.stringify(
      filter
    )}`;
    return this.request(url, "GET");
  }

  static putTimeTableUpdate(id, body) {
    return this.request(`time-table-structure/_update/${id}`, "PUT", body);
  }

  static deleteTimeTable(id) {
    return this.request(`time-table-structure/_delete/${id}`, "DELETE");
  }

  static TimeTableDelete(body) {
    return this.request(`time-table/_delete`, "DELETE", body);
  }

  static createTimeTable(payload) {
    const url = `time-table/_create`;
    return this.request(url, "POST", payload);
  }

  static createCalenderEventHoliday(payload) {
    const url = `calendar-events-holiday/_create`;
    return this.request(url, "POST", payload);
  }

  static getCalenderEvent(filter) {
    let url = `calendar-events-holiday/_search?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static putCalenderEvent(id, body) {
    return this.request(`calendar-events-holiday/_update/${id}`, "PUT", body);
  }

  static deleteCalenderEvent(id) {
    return this.request(`calendar-events-holiday/_delete/${id}`, "DELETE");
  }

  static createCalender(payload) {
    const url = `calendar/_create`;
    return this.request(url, "POST", payload);
  }

  static getCalender(filter) {
    if (Filter) {
      let url = `calendar/_search?filter=${filter}&start=0&count=-1&sort=-createdAt`;
      return this.request(url, "GET");
    }
    let url = `calendar/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static putCalender(id, body) {
    return this.request(`calendar/_update/${id}`, "PUT", body);
  }

  static deleteCalender(id) {
    return this.request(`calendar/_delete/${id}`, "DELETE");
  }

  static createAssignCalenderToClass(payload) {
    const url = `assign-calendar/_create`;
    return this.request(url, "POST", payload);
  }
  static getAssignCalenderToClass(filter) {
    let url = `assign-calendar/_search?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static putAssignCalenderToClass(id, body) {
    return this.request(`assign-calendar/_update/${id}`, "PUT", body);
  }

  static deleteAssignCalenderToClass(id) {
    return this.request(`assign-calendar/_delete/${id}`, "DELETE");
  }

  static postStudentSms(body, type) {
    return this.request(`communication/_send`, "POST", body);
  }

  static getCommunicationUsers() {
    let url = `users/communication/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getCommunicationHistory(filter) {
    let url = `communication/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `communication/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static postCommunicationReport(body) {
    return this.request(`communication/_getCommunicationReport`, "POST", body);
  }

  static postExperienceCertificate(body) {
    return this.request(`experience-certificate/_create`, "POST", body);
  }

  static getExperienceCertificate() {
    let url = `experience-certificate/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postBonafideCertificate(body) {
    return this.request(`bonafide-certificate/_create`, "POST", body);
  }

  static getBonafideCertificate() {
    let url = `bonafide-certificate/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postCharacterCertificate(body) {
    return this.request(`character-certificate/_create`, "POST", body);
  }

  static getCharacterCertificate() {
    let url = `character-certificate/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postTransferCertificate(body) {
    return this.request(`transfer-certificate/_create`, "POST", body);
  }

  static getTransferCertificate() {
    let url = `transfer-certificate/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postOfferLetter(body) {
    return this.request(`offer-letter/_create`, "POST", body);
  }

  static getOfferLetter() {
    let url = `offer-letter/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postGalleryPhotoVideo(body, entity) {
    return this.request(
      `gallery/s3/get-pre-signed-url/:${entity}`,
      "POST",
      body
    );
  }

  static postGalleryCreate(body) {
    return this.request(`gallery/_create`, "POST", body);
  }

  static galleryUpdate(id, body) {
    return this.request(`gallery/_update/${id}`, "PUT", body);
  }

  static galleryDisable(id, body) {
    return this.request(`gallery/disableEnableGallery/${id}`, "PATCH", body);
  }

  static getGallery() {
    let url = `gallery/_search?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static deleteGallery(id) {
    return this.request(`gallery/_delete/${id}`, "DELETE");
  }

  static postDiary(body) {
    return this.request(`diary/_create`, "POST", body);
  }

  static getDiary(filter) {
    let url = `diary/_search?&start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `diary/_search?&start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static deleteDairy(id) {
    return this.request(`diary/_delete/${id}`, "DELETE");
  }

  static dairyUpdate(id, body) {
    return this.request(`diary/update/${id}`, "PUT", body);
  }

  static postIncomeSetting(body) {
    return this.request(`income-expenditure-setting/_create`, "POST", body);
  }

  static postSubHead(body) {
    return this.request(
      `income-expenditure-setting/_createSubHead`,
      "POST",
      body
    );
  }

  static getExpenditureIncome(filter) {
    let url = `income-expenditure-setting/_search?filter=${filter}&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static getSubHeadExpen(filter) {
    if (filter) {
      let url = `income-expenditure-setting/_getSubHead?filter=${JSON.stringify(filter)}&start=0&count=-1&sort=-createdAt`;
      return this.request(url, "GET");
    }
    else {
      let url = `income-expenditure-setting/_getSubHead?&start=0&count=-1&sort=-createdAt`;
      return this.request(url, "GET");
    }
  }

  static IncomeUpdate(id, body) {
    return this.request(
      `income-expenditure-setting/_update/${id}`,
      "PUT",
      body
    );
  }

  static IncomeExpenditureSubHeadUpdate(id, body) {
    return this.request(
      `income-expenditure-setting/_updateSubHead/${id}`,
      "PUT",
      body
    );
  }

  static deleteIncomeExpenditure(id) {
    return this.request(`income-expenditure-setting/_delete/${id}`, "DELETE");
  }

  static deleteIncomeExpenditureSubHead(id) {
    return this.request(
      `income-expenditure-setting/_deleteSubHead/${id}`,
      "DELETE"
    );
  }

  static postStudentSyllabusPdf(body, id) {
    return this.request(`syllabus/s3/get-pre-signed-url/${id}`, "POST", body);
  }

  static postStudentSyllabus(body) {
    return this.request(`syllabus/_create`, "POST", body);
  }

  static getSyllabus(filter) {

    let url = `syllabus/_search?&start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `syllabus/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static deleteSyllabus(id) {
    return this.request(`syllabus/_delete/${id}`, "DELETE");
  }

  static postIncome(body) {
    return this.request(`income/_create`, "POST", body);
  }

  static getIncome(filter) {
    let url = `income/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `income/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static postIncomeReport(body) {
    return this.request(`income/getIncomeReport`, "POST", body);
  }

  static postExpenditureReport(body) {
    return this.request(`expenditure/getExpenditureReport`, "POST", body);
  }

  static getStudentByAdmissionNumber(admissionNo) {
    let url = `income/student/_search/${admissionNo}?&start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static UpdateIncomeByHead(id, body) {
    return this.request(`income/_update/${id}`, "PUT", body);
  }

  static deleteIncome(id) {
    return this.request(`income/_delete/${id}`, "DELETE");
  }

  static postExpenditure(body) {
    return this.request(`expenditure/_create`, "POST", body);
  }

  static postFeeReportData(body) {
    return this.request(`fee-payment/sessionReport`, "POST", body);
  }

  static getSessionFeeReportData(id) {
    return this.request(`fee-payment/session-report/due/${id}`, "GET");
  }

  static postTransportReportData(body) {
    return this.request(`transport-payment/sessionReport`, "POST", body);
  }

  static getExpenditure(filter) {
    let url = `expenditure/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `expenditure/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getFeeDiscountReportData(body) {
    let url = `fee-payment/discountReport`;
    return this.request(url, "POST", body);
  }

  static deleteExpenditure(id) {
    return this.request(`expenditure/_delete/${id}`, "DELETE");
  }

  static UpdateExpenditureByHead(id, body) {
    return this.request(`expenditure/_update/${id}`, "PUT", body);
  }


  static getRoutes() {
    let url = `routes/_search?start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static gethistoryTransportAllocation(filter) {
    let url = `school/transport-allocation-history/_search`
    if (filter) {
      url = `school/transport-allocation-history/_search?filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static postRoutes(body) {
    return this.request(`routes/_create`, "POST", body);
  }

  static UpdateRoute(id, body) {
    return this.request(`routes/_update/${id}`, "PUT", body);
  }
  static deleteRoute(id) {
    return this.request(`routes/_delete/${id}`, "DELETE");
  }

  static getStops(filter) {
    let url = `stops/_search?&start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `stops/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static postStops(body) {
    return this.request(`stops/_create`, "POST", body);
  }

  static UpdateStops(id, body) {
    return this.request(`stops/_update/${id}`, "PUT", body);
  }
  static deleteStops(id) {
    return this.request(`stops/_delete/${id}`, "DELETE");
  }

  static getVehicle() {
    let url = `vehicle/_search?start=0&count=-1&sort=-createdAt`;
    return this.request(url, "GET");
  }

  static postVehicle(body) {
    return this.request(`vehicle/_create`, "POST", body);
  }

  static UpdateVehicle(id, body) {
    return this.request(`vehicle/_update/${id}`, "PUT", body);
  }
  static deleteVehicle(id) {
    return this.request(`vehicle/_delete/${id}`, "DELETE");
  }

  static postTransportUser(body) {
    return this.request(`transport-user/_create`, "POST", body);
  }

  static getTransportUser(filter) {
    let url = `transport-user/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `transport-user/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  // static getTransportUser() {
  //   let url = `transport-user/_search?start=0&count=-1&sort=-createdAt`;
  //   return this.request(url, "GET");
  // }

  static deleteTransportUser(id) {
    return this.request(`transport-user/_delete/${id}`, "DELETE");
  }

  static patchTransportUserMobileNumber(body, _id) {
    return this.request(`transport-user/mobile-number/${_id}`, "PATCH", body);
  }

  static UpdateTransportUser(id, body) {
    return this.request(`transport-user/_update/${id}`, "PUT", body);
  }

  static getS3SignedUrlTransportUser(body) {
    return this.request(`transport-user/s3/get-pre-signed-url`, "PATCH", body);
  }

  static patchTransportUserImages(body, _id) {
    return this.request(`transport-user/avatar/${_id}`, "PATCH", body);
  }

  static patchTransportUser(_id, success) {
    let url = `transport-user/deactivate/${_id}`;
    console.log("url", url);
    return this.request(url, "PATCH", success);
  }

  // static getRouteStopsMapping() {
  //   let url = `route-stops-mapping/_search?start=0&count=-1&sort=-createdAt`;
  //   return this.request(url, "GET");
  // }

  static getRouteStopsMappings(id) {
    let url = `route-stops-mapping/_search/${id}`;
    return this.request(url, "GET");
  }

  static postRouteStopsMapping(body) {
    return this.request(`route-stops-mapping/_create`, "POST", body);
  }

  // static putRouteStopsMapping(id,body) {
  //   return this.request(`route-stops-mapping/_update/${id}`, "PUT", body);
  // }

  static putRouteStopsMapping(body) {
    return this.request(`route-stops-mapping/_updateOrder`, "PUT", body);
  }

  static deleteRouteStopsMapping(id) {
    return this.request(`route-stops-mapping/_delete/${id}`, "DELETE");
  }

  static getRoutevehicleMapping(filter) {
    let url = `route-vehicle-mapping/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `route-vehicle-mapping/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static postRoutevehicleMapping(body) {
    return this.request(`route-vehicle-mapping/_create`, "POST", body);
  }

  static UpdateRoutevehicleMapping(id, body) {
    return this.request(`route-vehicle-mapping/_update/${id}`, "PUT", body);
  }
  static deleteRoutevehicleMapping(id) {
    return this.request(`route-vehicle-mapping/_delete/${id}`, "DELETE");
  }

  static getBusOnBasicOfROutes(id) {
    let url = `route-vehicle-mapping/route-vehicle/${id}`;
    return this.request(url, "GET");
  }

  static getVehicleOnBasicOfBus(id) {
    let url = `route-vehicle-mapping/route/${id}`;
    return this.request(url, "GET");
  }

  static getRoutesOnBasisOfStop(id) {
    let url = `route-stops-mapping/stop-routes/${id}`;
    return this.request(url, "GET");
  }

  static getStudentStopAllocation(body) {
    // let url = `student-stop-allocation/_search?start=0&count=-1&sort=-createdAt`;
    // return this.request(url, "GET");
    return this.request(`student-stop-allocation/student/_search/allocated`, "POST", body);
  }

  static getAssignedStudentStopAllocation(body) {
    return this.request(`student-stop-allocation/student/_search/unallocated`, "POST", body)
  }

  static postStudentStopAllocation(body) {
    return this.request(`student-stop-allocation/_create`, "POST", body);
  }

  static putStudentStopAllocation(body) {
    return this.request(`student-stop-allocation/_transport-update`, "PUT", body);
  }

  static StudentStopAllocationDisable(body) {
    return this.request(`student-stop-allocation/_transport-disable`, "PUT", body);
  }

  static getEmployeeStopAllocation() {
    // let url = `employee-stop-allocation/_search?start=0&count=-1&sort=-createdAt`;
    let url = `employee-stop-allocation/_search`;
    return this.request(url, "GET");
  }

  static getEmployeeTransportAllocation() {
    // let url = `employee-stop-allocation/_search?start=0&count=-1&sort=-createdAt`;
    let url = `users/transportEmployeeList`;
    return this.request(url, "GET");
  }

  static postEmployeeStopAllocation(body) {
    return this.request(`employee-stop-allocation/_create`, "POST", body);
  }

  static UpdateStudentStopAllocation(id, body) {
    return this.request(`student-stop-allocation/_student-stop-update`, "PUT", body);
  }

  static getFuelConsumption() {
    let url = `fuel-consumption/_search`;
    return this.request(url, "GET");
  }

  static postFuelConsumption(body) {
    return this.request(`fuel-consumption/_create`, "POST", body);
  }

  static UpdateFuelConsumption(id, body) {
    return this.request(`fuel-consumption/_update/${id}`, "PUT", body);
  }
  static deleteFuelConsumption(id) {
    return this.request(`fuel-consumption/_delete/${id}`, "DELETE");
  }

  static putResult(body) {
    return this.request(`admission/update/result`, "PUT", body);
  }

  static putAdmissionPayment(body) {
    return this.request(`admission/payment/admission`, "PUT", body);
  }

  static feeCarryForward(body) {
    return this.request(`carry-forward/_create`, "POST", body);
  }

  static putCarryForward(body, _id) {
    return this.request(`carry-forward/_update/${_id}`, "PUT", body);
  }

  static deleteCarryForward(id) {
    return this.request(`carry-forward/_delete/${id}`, "DELETE");
  }

  static getCarryForward(filter) {
    let url = `carry-forward/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `carry-forward/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static studentFeeBulkUpload(body) {
    return this.request(`fee-payment/payBulkFee`, "POST", body);
  }

  static studentBulkCreate(body) {
    return this.request(`student-feeBulk-payment/_bulk`, "POST", body)
  }

  static feePaymentCorrection(body) {
    return this.request(`recent-payment/updateReceipt`, "PUT", body)
  }

  static transportPaymentCorrection(body) {
    return this.request(`recent-transport-payment/updateReceipt`, "PUT", body)
  }

  static feePaymentRevert(id) {
    return this.request(`recent-payment/revert-payment/${id}`, "PUT")
  }

  static getStudentFeeBulkUpload(id, filter) {
    let url = `student-fee-bulk-upload/_search?start=0&count=-1&sort=-createdAt`;
    if (id) {
      // const encodedFilter = encodeURIComponent(`{"studentId":{"$eq":"${id}"}}`);
      url = `student-fee-bulk-upload/_search?start=0&count=-1&sort=-createdAt`;
    }
    if (filter) {
      url = `student-fee-bulk-upload/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getViewSchudule(filter, body) {
    let url = `fee-configure/_viewSchedule?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `fee-configure/_viewSchedule?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET", body);
  }

  static getStudentViewSchudule(filter, body) {
    let url = `fee-configure/_viewSchedule2?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `fee-configure/_viewSchedule2?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "POST", body);
  }

  static getStudentFeePayment(body) {
    return this.request(`fee-payment/feeInfo`, "POST", body);
  }

  static getStudentTransportPayment(body) {
    return this.request(`transport-payment/feeInfo`, "POST", body);
  }

  static getCalculateFeeInfo(body) {
    return this.request(`fee-payment/calculateFeeInfo`, "POST", body);
  }

  static postCalculateTransportInfo(body) {
    return this.request(`transport-payment/calculateFeeInfo`, "POST", body);
  }

  static postCompletePayment(body) {
    return this.request(`transport-payment/payFee`, "POST", body);
  }

  static carryForwardPay(body) {
    return this.request(`transport-payment/pay-carryForward`, "POST", body);
  }

  static carryForwardFeePay(body) {
    return this.request(`fee-payment/pay-carryForward`, "POST", body);
  }

  static postDateRangefee(body) {
    return this.request(`fee-payment/dateRangeReport`, "POST", body);
  }

  static postDateRangeTransport(body) {
    return this.request(`transport-payment/dateRangeReport`, "POST", body);
  }

  static getFeeDiscount(filter) {
    return this.request(`discount/_search?filter=${JSON.stringify(filter)}`, "GET");
  }

  static getHomeWork(filter) {
    let url = `homework/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `homework/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getPaymentList(filter) {
    let url = `recent-payment/recentPaymentInfo?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `recent-payment/recentPaymentInfo?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getTransportPaymentList(filter) {
    let url = `recent-transport-payment/recentPaymentInfo?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `recent-transport-payment/recentPaymentInfo?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getChequeList(filter) {
    let url = `recent-payment/recentChequePaymentInfo?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `recent-payment/recentChequePaymentInfo?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getChequeListForAdmission(filter) {
    console.log(filter)
    // let url = `recent-payment/recentChequePaymentInfo?start=0&count=-1&sort=-createdAt`;
    let url = `admission/_search?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    // if (filter) {
    //   url = `recent-payment/recentChequePaymentInfo?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    // }
    return this.request(url, "GET");
  }

  static getChequePaymentResult(filter) {
    console.log(filter)
    // let url = `recent-payment/recentChequePaymentInfo?start=0&count=-1&sort=-createdAt`;
    let url = `admission/_searchCheque-payment?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    // if (filter) {
    //   url = `recent-payment/recentChequePaymentInfo?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    // }
    return this.request(url, "GET");
  }

  static getTransportChequeList(filter) {
    //recent-transport-payment/recentChequePaymentInfo?
    let url = `recent-transport-payment/recentChequePaymentInfo?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `recent-transport-payment/recentChequePaymentInfo?start=0&count=-1&filter=${filter}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static globalSearch(searchKey, sessionId) {
    const url = `school/school-admin/search/${sessionId}/?q=${searchKey}&start=0&count=-1`;
    return this.request(url, "GET");
  }

  static updateFeeChequeStatus(body) {
    return this.request(`recent-payment/updateChequeStatus`, "PATCH", body);
  }

  static updateChequeStatus(body) {

    return this.request(`transport-payment/updateChequeStatus`, "PATCH", body);
  }

  static updateChequeStatusInAdmission(updateDataId, chequeStatus) {
    return this.request(`admission/_updateCheckPayment/${updateDataId}/${chequeStatus}`, "PUT");
  }

  // static deleteTimeTable(id) {
  //   const url = `time-table/_delete/${id}`;
  //   return this.request(url, "DELETE");
  // }

  static editTimeTable(payload) {
    // const url = `time-table/_update/${id}`;
    console.log("payload Api---------------", payload)
    const url = `time-table/_update`;
    return this.request(url, "PUT", payload);
  }

  static getLeaveMangementStudent(filter) {
    let url = `leave/_search?start=0&count=-1&sort=-createdAt`;
    if (filter) {
      url = `leave/_search?start=0&count=-1&filter=${JSON.stringify(
        filter
      )}&sort=-createdAt`;
    }
    return this.request(url, "GET");
  }

  static getCommunicationUserHistory(filter) {
    let url = `communication/_search?filter={"recipientId": {"$eq":"${filter}"}}`;
    return this.request(url, "GET");
  }

  static patchUpdateStatueBiometric(schoolId, status) {
    return this.request(`school/biometric/${schoolId}/${status}`, "PATCH");
  }

  static putUpdateBiometric(body, schoolId) {
    return this.request(
      `school/biometric/update-biometric/${schoolId}`,
      "PUT",
      body
    );
  }

  static deleteBiometric(id) {
    return this.request(`school/biometric/delete-biometric/${id}`, "DELETE");
  }

  static updateLeaveMangementStudent(body) {
    return this.request(`leave/updateStatus`, "PATCH", body);
  }

  //===>> Exam API  

  static getExamSetting(field, sessionId) {
    return this.request(`exam-setting/${field}/_search/${sessionId}`, "GET");
  }

  static postExamSetting(field, body) {
    return this.request(`exam-setting/${field}/_create`, "POST", body);
  }

  static putExamSetting(field, body) {
    return this.request(`exam-setting/${field}/_update`, "PUT", body);
  }

  static deleteExamSetting(field, id) {
    return this.request(`exam-setting/${field}/_delete/${id}`, "DELETE");
  }

  static getGradeSetting(sessionId) {
    return this.request(`exam-setting/grade-setting/search/${sessionId}`, "GET");
  }

  static postGradeSetting(body) {
    return this.request(`exam-setting/grade-setting/create`, "POST", body);
  }

  static putGradeSetting(body) {
    return this.request(`exam-setting/grade-setting/update`, "PUT", body);
  }

  static getCommonSetting(sessionId) {
    return this.request(`exam-setting/common-setting/search/${sessionId}`, "GET");
  }

  static postCommonSetting(body) {
    return this.request(`exam-setting/common-setting/create`, "POST", body);
  }

  static putCommonSetting(id, body) {
    return this.request(`exam-setting/common-setting/update/${id}`, "PUT", body);
  }

  static postExamPattern(body) {
    return this.request(`exam-setting/exam-pattern/create`, "POST", body);
  }

  static getExamPattern(id) {
    return this.request(`exam-setting/exam-pattern/search/${id}`, "GET",);
  }

  static postAssignPattern(body) {
    return this.request(`exam-setting/exam-pattern/_assign`, "POST", body);
  }

  static getClassSectionExamPattern(id) {
    return this.request(`exam-setting/exam-pattern/class/search/${id}`, "GET");
  }

  static getExamAssignPattern(id) {
    return this.request(`exam-setting/exam-pattern/_assign/search/${id}`, "GET");
  }

  static putExamAssignPattern(id, body) {
    return this.request(`exam-setting/exam-pattern/_assign/update/${id}`, "PUT", body);
  }

  static getExamPatternOnBasisSession(id) {
    return this.request(`exam-setting/exam-schedule/exam-pattern-all/search/${id}`, "GET");
  }

  static getTermOnBasisPattern(ExamPatternId) {
    return this.request(`exam-setting/exam-schedule/exam-pattern-term/${ExamPatternId}`, "GET");
  }

  static getExamOnBasisTerm(patternId, termId) {
    return this.request(`exam-setting/exam-schedule/exam-pattern-term/${patternId}/${termId}`, "GET");
  }

  static getSubExamOnBasisExam(patternId, termId, examId) {
    return this.request(`exam-setting/exam-schedule/exam-pattern-term/${patternId}/${termId}/${examId}`, "GET");
  }

  static getClassSectionMappingSchedule(payload) {
    return this.request(`exam-setting/exam-schedule/class-section-list`, "POST", payload);
  }

  static submitExamSchedule(payload) {
    return this.request(`exam-setting/exam-schedule/create`, "POST", payload);
  }

  static getExamSchedule(id) {
    return this.request(`exam-setting/exam-schedule/search/${id}`, "GET")
  }

  static getExamScheduleList(id) {
    return this.request(`exam-setting/exam-schedule/exam-list/${id}/1`, "GET")
  }

  static getSubExamScheduleList(id) {
    return this.request(`exam-setting/exam-schedule/sub-exam-list/${id}/1`, "GET")
  }

  static deleteExamScheduleList(id) {
    return this.request(`exam-setting/exam-schedule/delete/${id}`, "DELETE")
  }

  static getAdditionalSubject(id) {
    return this.request(`exam-setting/exam-subject/search/${id}`, "GET")
  }

  static postAdditionalSubject(payload) {
    return this.request(`exam-setting/exam-subject/create`, "POST", payload)
  }

  static deleteAdditionalSubject(id) {
    return this.request(`exam-setting/exam-subject/delete/${id}`, "DELETE")
  }

  static getAssignAdditionalSubject(id) {
    return this.request(`exam-setting/exam-subject-class-mapping/search/${id}`, "GET")
  }

  static postAssignAdditionalSubject(payload) {
    return this.request(`exam-setting/exam-subject-class-mapping/create`, "POST", payload)
  }

  static deleteAssignAdditionalSubject(id) {
    return this.request(`exam-setting/exam-subject-class-mapping/delete/${id}`, "DELETE")
  }

  static getClassSectionExam(payload) {
    return this.request(`exam-setting/exam-schedule/scheduleList`, "POST", payload)
  }

  static getSubjectListExamTimeTable(payload) {
    return this.request(`exam-setting/exam-timetable/classList`, "POST", payload)
  }

  static getExamScheduleView(id) {
    return this.request(`exam-setting/exam-schedule/view-schedule/${id}`, "GET")
  }

  static postAdmitCard(payload) {
    return this.request(`exam-setting/generate-admitcard`, "POST", payload)
  }

  static postDeskSlip(payload) {
    return this.request(`exam-setting/generate-deskslip`, "POST", payload)
  }

  static postExamTimeTable(payload) {
    return this.request(`exam-setting/exam-timetable/create`, "POST", payload)
  }

  static fetchExamTimeTable(id) {
    return this.request(`exam-setting/exam-timetable/search/${id}`, "GET")
  }

  static fetchExamTimeTableView(id) {
    return this.request(`exam-setting/exam-timetable/view/${id}`, "GET")
  }

  static deleteExamTimeTable(id) {
    return this.request(`exam-setting/exam-timetable/delete/${id}`, "DELETE")
  }

  static postMarkAllocationExam(payload) {
    return this.request(`exam-setting/mark-allocation/exams`, "POST", payload)
  }

  static postMarkAllocationSubExam(payload) {
    return this.request(`exam-setting/mark-allocation/sub-exams`, "POST", payload)
  }

  static postMarkAllocationSubject(payload) {
    return this.request(`exam-setting/mark-allocation/subject`, "POST", payload)
  }

  static getMarkAllocation(payload) {
    return this.request(`exam-setting/mark-allocation/search`, "POST", payload)
  }

  static postMarkAllocationStudent(payload) {
    return this.request(`exam-setting/mark-allocation/student-list`, "POST", payload)
  }

  static postMarkAllocation(payload) {
    return this.request(`exam-setting/mark-allocation/insert`, "POST", payload)
  }

  static fetchMarkAllocationList(payload) {
    return this.request(`exam-setting/mark-allocation/search/exam`, "POST", payload)
  }

  static editMarkAllocationList(payload) {
    return this.request(`exam-setting/mark-allocation/update`, "POST", payload)
  }

  static fetchAllSubjectMarkAllocation(payload) {
    return this.request(`exam-setting/mark-allocation/subject/exam`, "POST", payload)
  }

  static fetchClassExamTimeTable(id) {
    return this.request(`exam-setting/exam-timetable/class-list/${id}`, "GET")
  }

  static fetchSectionExamTimeTable(sessionId, classId) {
    return this.request(`exam-setting/exam-timetable/section-list/${sessionId}/${classId}`, "GET")
  }

  static fetchTermDetailExamTimeTable(sessionId, classId, sectionId) {
    return this.request(`exam-setting/exam-timetable/term-detail/${sessionId}/${classId}/${sectionId}`, "GET")
  }

  static fetchMarkAllocationTerm(payload) {
    return this.request(`exam-setting/mark-allocation/terms`, "POST", payload)
  }

  static getNonExamList(id) {
    return this.request(`exam-setting/exam-setting/non-exam-point/_search/${id}`, "GET")
  }

  static postNonExamList(payload) {
    return this.request(`exam-setting/exam-setting/non-exam-point/_create`, "POST", payload)
  }

  static updateNonExamLists(id, payload) {
    return this.request(`exam-setting/exam-setting/non-exam-point/_update/${id}`, "POST", payload)
  }

  static deleteNonExamList(id) {
    return this.request(`exam-setting/exam-setting/non-exam-point/_delete/${id}`, "DELETE",)
  }

  static getNonExamSubject(payload) {
    return this.request(`exam-setting/mark-allocation/non-exam-subjects`, "POST", payload)
  }

  static postNonExam(payload) {
    return this.request(`exam-setting/mark-allocation/non-exam/create`, "POST", payload)
  }

  static getNonExamSearch(payload) {
    return this.request(`exam-setting/mark-allocation/non-exam/search`, "POST", payload)
  }

  static updateNonExamList(paylaod) {
    return this.request(`exam-setting/mark-allocation/non-exam/update`, "POST", paylaod)
  }

  static templateListClassVise(paylaod) {
    return this.request(`template-setting/template-list-for-class-mapping`, "POST", paylaod)
  }

  static addTemplateMapping(paylaod) {
    return this.request(`template-setting/create-template-class-mapping`, "POST", paylaod)
  }

  static getTemplateListExam(paylaod) {
    return this.request(`template-setting/search-template-class-mapping`, "POST", paylaod)
  }

  static viewTemplate(paylaod) {
    return this.request(`template-setting/view-template-class-mapping`, "POST", paylaod)
  }

  static deleteTemplate(paylaod) {
    return this.request(`template-setting/delete-template-class-mapping`, "POST", paylaod)
  }

  static fetchAllTemplate(paylaod) {
    return this.request(`template-setting/search-template-setting`, "POST", paylaod)
  }

  static updateTemplate(paylaod) {
    return this.request(`template-setting/update-template-setting`, "POST", paylaod)
  }

  static templateListDelete(paylaod) {
    return this.request(`template-setting/delete-template-setting`, "POST", paylaod)
  }

  static createTemplateExam(paylaod) {
    return this.request(`template-setting/create-template-setting`, "POST", paylaod)
  }

  static viewTemplatelist(paylaod) {
    return this.request(`template-setting/view-template-setting`, "POST", paylaod)
  }

  static fetchExamReport(paylaod) {
    return this.request(`exam-setting/mark-allocation/exam/search`, "POST", paylaod)
  }

  static fetchExamReportData(payload) {
    return this.request(`exam-setting/mark-allocation/exam/view`, "POST", payload)
  }

  static fetchTermReportData(payload) {
    return this.request(`exam-setting/mark-allocation/term/search`, "POST", payload)
  }

  static postRemark(payload) {
    return this.request(`exam-setting/remark-setting/create`, "POST", payload);
  }

  static getRemark(payload) {
    return this.request(`exam-setting/remark-setting/search`, "POST", payload);
  }

  static updateRemark(payload) {
    return this.request(`exam-setting/remark-setting/update`, "POST", payload);
  }

  static deleteRemark(payload) {
    return this.request(`exam-setting/remark-setting/delete`, "POST", payload);
  }

  static createRemarkMapping(payload) {
    return this.request(`exam-setting/remark-student-mapping/create`, "POST", payload);
  }

  static getRemarkMappingList(payload) {
    return this.request(`exam-setting/remark-student-mapping/search`, "POST", payload);
  }

  static fetchTermAttendanceList(payload) {
    return this.request(`exam-setting/term-attendance/term-list`, "POST", payload)
  }

  static createTermAttendance(payload) {
    return this.request(`exam-setting/term-attendance/create`, "POST", payload)
  }

  static fetchAttendanceList(payload) {
    return this.request(`exam-setting/term-attendance/search`, "POST", payload)
  }

  static updateAttendanceList(payload) {
    return this.request(`exam-setting/term-attendance/update`, "POST", payload)
  }

  static EditTermAttendanceList(payload) {
    return this.request(`exam-setting/edit-term-attendance/term-list`, "POST", payload)
  }

  static fetchMarksheet(payload) {
    return this.request(`exam-setting/marksheet/search`, "POST", payload)
  }

  static fetchCrossList(payload) {
    return this.request(`exam-setting/mark-allocation/cross-list`, "POST", payload)
  }


}

