import { FETCH_FEE_INFO_FAILURE ,FETCH_DASHBOARD_INFO_FAILURE ,FETCH_DASHBOARD_INFO_REQUEST ,FETCH_DASHBOARD_INFO_SUCCESS,
    FETCH_FEE_INFO_REQUEST,FETCH_FEE_INFO_SUCCESS
 } from "../../ActionTypes";

const initialState = {
    dashboardInfo: null,
    feeInfo: null,
    loading: {
        dashboardInfo: true,
        feeInfo: true
    },
    error: null
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_INFO_REQUEST:
            return { ...state, loading: { ...state.loading, dashboardInfo: true } };
        case FETCH_DASHBOARD_INFO_SUCCESS:
            return { ...state, dashboardInfo: action.payload, loading: { ...state.loading, dashboardInfo: false } };
        case FETCH_DASHBOARD_INFO_FAILURE:
            return { ...state, error: action.payload, loading: { ...state.loading, dashboardInfo: false } };
        case FETCH_FEE_INFO_REQUEST:
            return { ...state, loading: { ...state.loading, feeInfo: true } };
        case FETCH_FEE_INFO_SUCCESS:
            return { ...state, feeInfo: action.payload, loading: { ...state.loading, feeInfo: false } };
        case FETCH_FEE_INFO_FAILURE:
            return { ...state, error: action.payload, loading: { ...state.loading, feeInfo: false } };
        default:
            return state;
    }
};

export default DashboardReducer;