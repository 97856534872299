export const ROLES = {
    'SuperAdmin':'1',
    'Admin':'2',
    'SubAdmin':'3'
};
// Object.freeze(Roles);

export const ROLES_BY_NAME = {
    SuperAdmin:'SuperAdmin',
    Admin:'Admin',
    SubAdmin:'SubAdmin',
    Employee:'Employee',
};
// Object.freeze(ROLES_BY_NAME);

// export default {
//     ROLE,
//     ROLES_BY_NAME    
// };